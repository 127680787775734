import { WaiverRequestStatus } from '@prisma/client';
import * as React from 'react';
import { useQueries } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { Appointment } from '../../interfaces';
import {
  getAppointmentWaiver,
  getWaiverFromRequestSession,
  trackWaiverView,
} from '../../services/waivers';

function useWaiver({
  appointment,
  sessionId,
  isPreview,
}: {
  appointment: Appointment | undefined;
  sessionId?: string;
  isPreview?: boolean;
}) {
  const [params] = useSearchParams();
  const previewWaiverId = isPreview ? params.get('waiver') : undefined;

  const waiverId = isPreview
    ? previewWaiverId
    : appointment
    ? appointment?.waiverRequest?.waiverId
    : '';

  const needsWaiver = React.useMemo(
    () =>
      (isPreview && !!previewWaiverId) ||
      !!sessionId ||
      (!!waiverId &&
        (appointment?.waiverRequest?.status === WaiverRequestStatus.QUEUED ||
          appointment?.waiverRequest?.status === WaiverRequestStatus.SENT) &&
        isSameDay(new Date(), new Date(appointment!.startDateTime || ''))),
    [isPreview, previewWaiverId, waiverId, appointment, sessionId],
  );

  const waiverType =
    needsWaiver && !!waiverId ? 'appointment' : sessionId ? 'session' : 'none';

  const [baseWaiverQuery, sessionWaiverQuery] = useQueries({
    queries: [
      {
        queryKey: ['waiver', waiverId, needsWaiver, isPreview],
        queryFn: () => getAppointmentWaiver(waiverId!, isPreview),
        enabled: !!needsWaiver && !!waiverId && waiverType === 'appointment',
      },
      {
        queryKey: ['waiver-session', sessionId],
        queryFn: () => getWaiverFromRequestSession(sessionId!),
        enabled: !!sessionId && waiverType === 'session',
      },
      {
        queryKey: ['waiver-view', appointment?.waiverRequest?.id],
        queryFn: () =>
          trackWaiverView({ waiverRequestId: appointment?.waiverRequest?.id! }),
        enabled:
          !!needsWaiver &&
          !!appointment?.businessId &&
          !!appointment?.waiverRequest?.id,
      },
    ],
  });

  const {
    data: waiver,
    isLoading,
    isFetching,
    isError,
    error,
  } = waiverType === 'appointment'
    ? baseWaiverQuery
    : waiverType === 'session'
    ? {
        data: sessionWaiverQuery.data?.waiver,
        isLoading: sessionWaiverQuery.isLoading,
        isFetching: sessionWaiverQuery.isFetching,
        isError: sessionWaiverQuery.isError,
        error: sessionWaiverQuery.error,
      }
    : {
        data: null,
        isLoading: false,
        isFetching: false,
        isError: false,
        error: null,
      };
  const sessionAppointment =
    waiverType === 'session' ? sessionWaiverQuery.data?.appointment : undefined;
  const sessionWaiverRequest =
    waiverType === 'session'
      ? sessionWaiverQuery.data?.waiverRequest
      : undefined;
  const session =
    waiverType === 'session' ? sessionWaiverQuery.data?.session : undefined;

  return {
    previewWaiverId,
    needsWaiver,
    waiver,
    isLoading,
    isFetching,
    isError,
    error,
    appointment: appointment || sessionAppointment,
    session,
    sessionWaiverRequest,
    waiverType,
  };
}

export default useWaiver;
