import React from 'react';
import { ConversationData } from 'talkjs/all';
import Talk from 'talkjs';
import { URL_FRIENDLY } from '../../constants/regex';
import useCleanParam from '../useCleanParam';
import useGetConversation from './useGetConversation';

function useParamConversation({
  selectedConversation,
  setSelectedConversation,
}: {
  selectedConversation: Talk.ConversationData | null;
  setSelectedConversation: React.Dispatch<
    React.SetStateAction<Talk.ConversationData | null>
  >;
}) {
  const conversationId = useCleanParam({
    key: 'conversationId',
    regex: URL_FRIENDLY,
  });
  const [isParamConversationSet, setIsParamConversationSet] =
    React.useState(false);

  const onSelectParamConversation = React.useCallback(
    (result: ConversationData) => {
      if (
        !result ||
        !conversationId ||
        !!selectedConversation ||
        isParamConversationSet
      )
        return;
      setSelectedConversation(result);
      setIsParamConversationSet(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversationId, isParamConversationSet, selectedConversation],
  );

  const {
    conversation,
    isEnabled,
    isLoading: isLoadingConvo,
  } = useGetConversation(conversationId, {
    onSuccess: onSelectParamConversation,
  });
  const isLoading = isEnabled && isLoadingConvo && !isParamConversationSet;

  return {
    isLoading,
    conversationId,
    conversation,
  };
}

export default useParamConversation;
