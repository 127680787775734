import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ShopPage from './routes/shop';
import ProfilePage from './routes/profile';
import ArtistPage from './routes/artist';
import BookingPage from './routes/booking';
import ReschedulePage from './routes/reschedule';
import ChatPage from './routes/chat';
import GeneralRequest from './routes/generalRequest';
import ReviewPage from './routes/review';
import PreviewWaiverPage from './routes/waiver';
import theme from './constants/theme';
import Service from './routes/service';
import UnsubscribePage from './routes/unsubscribe';
import NotFound from './routes/404';
import CheckoutPage from './routes/checkout';
import ReceiptPage from './routes/receipt';
import WaiverSessionPage from './routes/waiverSessionPage';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="artist/:urlPath" element={<ArtistPage />} />
            <Route path="shop/:urlPath" element={<ShopPage />} />
            <Route path="shop/:shopUrl/:artistUrl" element={<ProfilePage />} />
            <Route path="shop/:shopUrl/request" element={<GeneralRequest />} />
            <Route
              path="shop/:shopUrl/service/:serviceId"
              element={<Service />}
            />
            <Route
              path="shop/:shopUrl/preview-waiver"
              element={<PreviewWaiverPage />}
            />
            <Route
              path="shop/:shopUrl/waiver-session/:sessionId"
              element={<WaiverSessionPage />}
            />
            <Route path="/review/:reviewLinkId" element={<ReviewPage />} />
            <Route path="booking/:appointmentId" element={<BookingPage />} />
            <Route path="booking/" element={<BookingPage />} />
            <Route
              path="reschedule/:appointmentId"
              element={<ReschedulePage />}
            />
            <Route path="chat" element={<ChatPage />} />
            <Route path="chat/:conversationId" element={<ChatPage />} />
            <Route path="unsubscribe" element={<UnsubscribePage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/checkout/:paymentId" element={<CheckoutPage />} />
            <Route path="/receipt/:paymentId" element={<ReceiptPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
