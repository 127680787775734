import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { Appointments } from '@prisma/client';
import { Appointment, DayItem, Employee, Service } from '../../../interfaces';
import Month from './Days/Month';
import CalendarHeader from './CalendarHeader';
import CalendarNav from './CalendarNav';
import TimeList from './Times/TimeList';
import useEmployeeAvailableDays from '../../../hooks/appointments/useEmployeeAvailableDays';
import { ScheduleAndConfirmStep } from '../../../types/appointments';
import { getTakenSlots } from '../../../utils/appointment';
import { FullService } from '../../../types/services';

type Props = {
  duration: number;
  selectedDay: Date;
  dayIsSelected: boolean;
  setSelectedDay: (date: Date) => void;
  setDayIsSelected: (isSelected: boolean) => void;
  setSelectedDateTime: (date: Date) => void;
  employee: Employee;
  appointment?: Appointment | Appointments;
  setStep?: (step: ScheduleAndConfirmStep) => void;
  service?: Service | FullService;
  nextStep?: () => void;
  bufferDays?: number;
  otherSelectedDates?: Date[];
};

export default function Calendar({
  duration,
  appointment,
  employee,
  selectedDay,
  dayIsSelected,
  setSelectedDay,
  setDayIsSelected,
  setSelectedDateTime,
  setStep,
  service,
  nextStep,
  bufferDays,
  otherSelectedDates,
}: Props) {
  const [timezone] = React.useState(
    appointment?.timezone || employee.user.timezone || 'US/Pacific',
  );
  const [showOnlyPreferred, setShowOnlyPreferred] = React.useState(false);

  const takenSlots = getTakenSlots(otherSelectedDates ?? [], duration);

  const {
    query: {
      data: availableDays,
      isFetching,
      isError,
      isLoading: isLoadingInitial,
    },
    state: { initialCurrentMonth, selectedMonth, setSelectedMonth },
    noAvailableDays,
  } = useEmployeeAvailableDays({
    duration,
    employee,
    service,
    appointment,
    bufferDays,
    takenSlots,
  });

  const loading = isFetching;
  const uiMonth = selectedMonth || initialCurrentMonth;

  React.useEffect(() => {
    const length = availableDays?.filter((item: DayItem) => item.preferred)
      .length;

    if (length && length > 2) {
      setShowOnlyPreferred(true);
    }
  }, [availableDays]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={dayIsSelected ? 8 : 12}
        className={`calendar-box ${dayIsSelected ? 'hidden' : ''}`}>
        <Box className="form-header-title">
          <Typography variant="h2" className="form-header">
            Appointment with{' '}
            {employee.displayName || employee.user.firstName || 'your artist'}
          </Typography>
          <Typography variant="body2" className="form-sub-header">
            Select a day for your appointment
          </Typography>
        </Box>
        <CalendarNav
          selectedMonth={uiMonth}
          setSelectedMonth={setSelectedMonth}
          showOnlyPreferred={showOnlyPreferred}
          setShowOnlyPreferred={setShowOnlyPreferred}
          bookAheadLimitDays={employee.bookAheadLimitDays}
          hideButtons={!!noAvailableDays || isLoadingInitial}
        />
        <CalendarHeader />
        {!loading && availableDays ? (
          <Month
            dayItems={availableDays}
            selectedMonth={uiMonth}
            setSelectedDay={setSelectedDay}
            setDayIsSelected={setDayIsSelected}
            showOnlyPreferred={showOnlyPreferred}
          />
        ) : (
          <div style={{ paddingTop: 140, paddingBottom: 140 }}>Loading...</div>
        )}
        {isError && !availableDays ? (
          <Typography variant="inherit">
            Error loading calendar for this provider. Please refresh the page
            and try again
          </Typography>
        ) : null}
      </Grid>
      {dayIsSelected ? (
        <TimeList
          appointment={appointment}
          selectedDay={selectedDay}
          setDayIsSelected={setDayIsSelected}
          duration={duration}
          employee={employee}
          timezone={timezone}
          setSelectedDateTime={setSelectedDateTime}
          setStep={setStep}
          nextStep={nextStep}
          service={service}
          takenSlots={takenSlots}
        />
      ) : null}
    </Grid>
  );
}
