import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import useLogReview from '../hooks/reviews/useLogReview';
import { useReviewSubmission } from '../hooks/reviews/useReviewSubmission';
import ReviewForm from '../components/Reviews/ReviewForm';
import CompletedReview from '../components/Reviews/CompletedReview';
import useCleanParam from '../hooks/useCleanParam';
import { URL_FRIENDLY } from '../constants/regex';

export default function ReviewPage() {
  const reviewLinkId =
    useCleanParam({ key: 'reviewLinkId', regex: URL_FRIENDLY }) || '';
  const [response, setResponse] = React.useState<
    'positive' | 'negative' | null
  >(null);
  const [feedback, setFeedback] = React.useState('');

  const [negativeFeedbackPosted, setNegativeFeedbackPosted] =
    React.useState(false);

  const { data: reviewData, isLoading, isError } = useLogReview(reviewLinkId);
  const { reviewSubmissionMutation, error: submitError } = useReviewSubmission({
    reviewLinkId,
  });

  const { business, employee, reviewLink } = reviewData || {};
  const isComplete =
    reviewLink?.reviewStatus === 'COMPLETED' ||
    response === 'positive' ||
    negativeFeedbackPosted;
  const entityName = employee?.displayName || business?.name;
  const reviewLinkUrl = employee?.reviewLink || business?.reviewLink;
  const isPositive =
    response === 'positive' || !!reviewLink?.reviews.some(r => r.rating === 5);

  const handlePositiveReview = async () => {
    await reviewSubmissionMutation.mutateAsync({ rating: 5 });
  };

  const handleNegativeReview = async () => {
    await reviewSubmissionMutation.mutateAsync(
      { rating: 1, feedback },
      {
        onSuccess: () => {
          setNegativeFeedbackPosted(true);
        },
      },
    );
  };

  if (isLoading)
    return (
      <Container maxWidth="lg" className="data-error">
        <Typography variant="body1">Loading...</Typography>
      </Container>
    );

  if (isError) {
    return (
      <Box
        style={{
          height: window.innerHeight,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
          margin: 'auto',
        }}>
        <TopBar title="Error" subtitle="Your appointment" />
        <Box style={{ flex: '1 1 0', marginTop: 63, paddingBottom: 165 }}>
          <Typography variant="h1">
            Error loading this review page. Please ensure you entered the review
            URL correctly and refresh the page to try again.
          </Typography>
        </Box>
        <PorterFooter />
      </Box>
    );
  }

  return (
    <Container
      maxWidth="md"
      className="shop-page"
      style={{ padding: 0, height: '100svh', display: 'flex' }}>
      <Box className="review-form">
        {!isComplete && (
          <ReviewForm
            entityName={entityName}
            employee={employee}
            response={response}
            setResponse={setResponse}
            onPositiveReview={handlePositiveReview}
            onNegativeReview={handleNegativeReview}
            feedback={feedback}
            setFeedback={setFeedback}
            error={submitError}
          />
        )}
        {isComplete && (
          <CompletedReview
            entityName={entityName}
            reviewLinkUrl={reviewLinkUrl}
            wasPositive={isPositive}
          />
        )}
      </Box>
    </Container>
  );
}
