import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import BottomActionBar from '../Global/BottomActionBar';
import {
  Business,
  Employee,
  FormValues,
  FullIntakeQuestion,
} from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  employee: Employee | undefined;
  intakeQuestion?: FullIntakeQuestion;
  business: Business | undefined;
};

const validationSchema = yup.object({
  size: yup.string().required('Required'),
});

const employeeIdsTextInput = [
  'cln0oy4xc0000s60u3ntgnt2b',
  'clgfw9ofc0000s60uoi4vlcod',
  'clzan8ftq008i8mcr5jlog5ev',
  'cm47fhjd7004prruhdc16yb8o',
  'cm4em3iea00q8sz5dbrg33awx',
  'cm5697gbo01r011osbqywg1z0',
];

const businessIdsTextInput = ['cm47ffwzl004lrruh8zips51w'];

function Size({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  employee,
  intakeQuestion,
  business,
}: Props) {
  const theme = useTheme();

  const useTextInput =
    businessIdsTextInput.includes(business?.businessId ?? '') ||
    employeeIdsTextInput.includes(employee?.employeeId ?? '');

  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle ||
      'How big do you want your tattoo to be?',
    subHeader: intakeQuestion?.customQuestionSubheader || '',
  };

  function labelMakerLargeSizes(size: string) {
    let src = '';
    let labelName = '';
    let labelDesc = '';

    switch (size) {
      case 'md':
        src = '/img/hand.svg';
        labelName = 'Medium';
        labelDesc = '~7x7 inches. About the size of your hand';
        break;

      case 'lg':
        src = '/img/new-document.svg';
        labelName = 'Large';
        labelDesc =
          'Lower part half sleeve, outside upper arm, hip, front back or side of calf or thigh, etc.';
        break;

      case 'xl':
        src = '/img/sleeve.svg';
        labelName = 'Extra Large';
        labelDesc = 'Full sleeve, full leg sleeve, full back tattoo, etc.';
        break;

      default:
        src = '';
        labelName = '';
        labelDesc = '';
    }

    return (
      <div className="size-label">
        <div className="size-label-text">
          <Typography variant="h3">{labelName}</Typography>
          <Typography variant="body2" color={theme.palette.medGrey.main}>
            {labelDesc}
          </Typography>
        </div>
        <img src={src} alt="Tattoo Size Label" className="size-icon" />
      </div>
    );
  }

  function labelMakerHermosa(size: string) {
    let src = '';
    let labelName = '';
    let labelDesc = '';

    switch (size) {
      case 'sm':
        src = '/img/postage-stamp.svg';
        labelName = 'Small';
        labelDesc = 'Less than 2 x 2 inches. About the size of a postage stamp';
        break;
      case 'md':
        src = '/img/credit-card.svg';
        labelName = 'Medium';
        labelDesc = 'Less than 4 x 4 inches. About the size of a credit card';
        break;
      case 'lg':
        src = '/img/hand.svg';
        labelName = 'Large';
        labelDesc = 'Around 6 x 6 inches. About the size of your hand';
        break;
      case 'xl':
        src = '/img/sleeve.svg';
        labelName = 'Extra Large';
        labelDesc = 'Half, Full Sleeves, or anything larger than your hand.';
        break;
      default:
        src = '';
        labelName = '';
        labelDesc = '';
    }

    return (
      <div className="size-label">
        <div className="size-label-text">
          <Typography variant="h3">{labelName}</Typography>
          <Typography variant="body2" color={theme.palette.medGrey.main}>
            {labelDesc}
          </Typography>
        </div>
        <img src={src} alt="Tattoo Size Label" className="size-icon" />
      </div>
    );
  }

  const formik = useFormik({
    initialValues: {
      size: formValues.size,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('size', values.size!);
      nextStep();
    },
  });

  switch (true) {
    case useTextInput:
      return (
        <form onSubmit={formik.handleSubmit} id="tattoo-size">
          <FormHeader
            header={questionValues.header}
            subHeader={questionValues.subHeader}
          />
          <Box className="input-container">
            <TextField
              id="size"
              name="size"
              label="Tattoo Size"
              value={formik.values.size}
              onChange={formik.handleChange}
              error={formik.touched.size && Boolean(formik.errors.size)}
              helperText={formik.touched.size && formik.errors.size}
            />
          </Box>
          <BottomActionBar
            primaryText="Continue"
            isSubmit
            primaryDisabled={!formik.isValid}
            secondaryText="Back"
            secondaryAction={prevStep}
          />
        </form>
      );

    default:
      return (
        <form onSubmit={formik.handleSubmit} id="tattoo-size">
          <FormHeader
            header={questionValues.header}
            subHeader={questionValues.subHeader}
          />
          <Box className="input-container">
            <RadioGroup
              row
              aria-labelledby="radio-size"
              name="size"
              id="size-radio-group"
              value={formik.values.size}
              onChange={formik.handleChange}>
              <Grid container spacing={{ xs: 0, sm: 2 }} rowSpacing={2}>
                {employee?.employeeId === 'clmi13bfw0002s60uj3ddtgod' ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="Medium"
                        control={
                          <Radio inputProps={{ className: 'radio-size' }} />
                        }
                        label={labelMakerLargeSizes('md')}
                        className={`size-label md ${
                          formik.values.size === 'Medium' ? 'checked' : ''
                        }`}
                        labelPlacement="top"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="Large"
                        control={
                          <Radio inputProps={{ className: 'radio-size' }} />
                        }
                        label={labelMakerLargeSizes('lg')}
                        labelPlacement="top"
                        className={`size-label lg ${
                          formik.values.size === 'Large' ? 'checked' : ''
                        }`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="Extra Large"
                        control={
                          <Radio inputProps={{ className: 'radio-size' }} />
                        }
                        label={labelMakerLargeSizes('xl')}
                        labelPlacement="top"
                        className={`size-label xl ${
                          formik.values.size === 'Extra Large' ? 'checked' : ''
                        }`}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="Extra Small"
                        control={
                          <Radio inputProps={{ className: 'radio-size' }} />
                        }
                        label={labelMakerHermosa('sm')}
                        className={`size-label sm ${
                          formik.values.size === 'Extra Small' ? 'checked' : ''
                        }`}
                        labelPlacement="top"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="Small"
                        control={
                          <Radio inputProps={{ className: 'radio-size' }} />
                        }
                        label={labelMakerHermosa('md')}
                        labelPlacement="top"
                        className={`size-label md ${
                          formik.values.size === 'Small' ? 'checked' : ''
                        }`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="Medium"
                        control={
                          <Radio inputProps={{ className: 'radio-size' }} />
                        }
                        label={labelMakerHermosa('lg')}
                        labelPlacement="top"
                        className={`size-label lg ${
                          formik.values.size === 'Medium' ? 'checked' : ''
                        }`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="Large"
                        control={
                          <Radio inputProps={{ className: 'radio-size' }} />
                        }
                        label={labelMakerHermosa('xl')}
                        labelPlacement="top"
                        className={`size-label xl ${
                          formik.values.size === 'Large' ? 'checked' : ''
                        }`}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </RadioGroup>
          </Box>
          <BottomActionBar
            primaryText="Continue"
            isSubmit
            primaryDisabled={!formik.isValid}
            secondaryText="Back"
            secondaryAction={prevStep}
          />
        </form>
      );
  }
}

export default Size;
