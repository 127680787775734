import Grid from '@mui/material/Grid';
import { DayItem } from '../../../../interfaces';
import Day from './Day';

type Props = {
  dayItems: DayItem[];
  showOnlyPreferred: boolean;
  selectedMonth: Date;
  setSelectedDay: (date: Date) => void;
  setDayIsSelected: (isSelected: boolean) => void;
};

export default function DaysRow({
  dayItems,
  showOnlyPreferred,
  selectedMonth,
  setSelectedDay,
  setDayIsSelected,
}: Props) {
  return (
    <div className="calendar-row">
      <Grid container spacing={0} columns={7}>
        {dayItems.map(dayItem => (
          <Day
            day={dayItem}
            showOnlyPreferred={showOnlyPreferred}
            selectedMonth={selectedMonth}
            setSelectedDay={setSelectedDay}
            setDayIsSelected={setDayIsSelected}
            key={dayItem.date}
          />
        ))}
      </Grid>
    </div>
  );
}
