import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Employee, Appointment, Business } from '../../../interfaces';
import '../Booking.css';
import AppointmentDetailsList from '../General/AppointmentDetailsList';
import BottomActionBar from '../../Global/BottomActionBar';
import Calendar from '../Scheduler/Calendar';
import Confirm from '../Confirm/Confirm';

type Props = {
  appointment: Appointment;
  employee: Employee;
  business: Business | undefined;
};

function Reschedule({ appointment, employee, business }: Props) {
  const [selectedDay, setSelectedDay] = React.useState(new Date());
  const [selectedDateTime, setSelectedDateTime] = React.useState(new Date());
  const [dayIsSelected, setDayIsSelected] = React.useState(false);

  const [step, setStep] = React.useState<string>('details');

  switch (step) {
    case 'details':
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
            paddingBottom: 165,
          }}>
          <Box className="form-header-title">
            <Typography variant="h2" className="form-header">
              Reschedule your appointment with{' '}
              {employee.displayName || employee.user.firstName || 'your artist'}
            </Typography>
            <Typography variant="body2" className="form-sub-header">
              Select a new date and time for your appointment
            </Typography>
          </Box>
          <AppointmentDetailsList
            appointment={appointment}
            employee={employee}
            business={business}
            aptDate={new Date(appointment.startDateTime!)}
            appointmentDate
            location
            price
            cancellation
            isReschedule
          />
          <BottomActionBar
            primaryText="Choose Date"
            primaryAction={() => {
              setStep('dateTime');
              window.scrollTo(0, 0);
            }}
          />
        </Box>
      );

    case 'dateTime':
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
            paddingBottom: dayIsSelected ? 125 : 38,
          }}>
          {appointment && employee && (
            <Calendar
              duration={appointment.appointmentLengthInMinutes!}
              employee={employee}
              appointment={appointment}
              selectedDay={selectedDay}
              dayIsSelected={dayIsSelected}
              setSelectedDay={setSelectedDay}
              setSelectedDateTime={setSelectedDateTime}
              setDayIsSelected={setDayIsSelected}
              setStep={setStep}
            />
          )}
        </Box>
      );

    case 'confirm':
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
          }}>
          <Confirm
            appointment={appointment}
            employee={employee}
            business={business}
            appointmentsToDates={{
              [appointment.appointmentId!]: selectedDateTime,
            }}
            status="reschedule"
            isBack
            backAction={() => setStep('dateTime')}
            isReschedule
          />
        </Box>
      );

    default:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
            paddingBottom: 125,
          }}>
          <Typography variant="h1">
            There was an error loading your booking. Please reach out to our
            support team using the help icon above.
          </Typography>
        </Box>
      );
  }
}

export default Reschedule;
