import { Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PageLayout from '../../Global/PageLayout';

function CompletedWaiverRequest() {
  return (
    <PageLayout showBottomActionBar={false}>
      <Stack gap={1.5} py={4} alignItems="center" textAlign="left">
        <Typography variant="h2" component="p" textAlign="inherit">
          Waiver Request Completed
        </Typography>
        <Typography variant="body1" component="p" textAlign="inherit">
          You can now close this page.
        </Typography>
        <CheckCircleIcon
          sx={{
            fontSize: 48,
          }}
          color="success"
        />
      </Stack>
    </PageLayout>
  );
}

export default CompletedWaiverRequest;
