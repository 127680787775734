import { useQuery } from '@tanstack/react-query';
import { Appointment } from '../../interfaces';
import { getAppointment } from '../../services/appointment';
import useUser from '../global/useUser';

function useGetAppointment(appointmentId: string | null) {
  const { user } = useUser();

  const isEnabled = !!appointmentId && !!user?.userId;

  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
    isPlaceholderData,
  } = useQuery({
    queryKey: ['appointment', appointmentId],
    queryFn: async () => {
      const appointment = await getAppointment(appointmentId!);
      return appointment as Appointment;
    },
    enabled: isEnabled,
  });

  return {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
    isPlaceholderData,
    isEnabled,
  };
}

export default useGetAppointment;
