import { Box, SxProps, Theme, useTheme } from '@mui/material';
import React from 'react';
import { sxToObject } from '../../../utils/global';

interface Props extends React.PropsWithChildren {
  sx?: SxProps<Theme>;
}

function BookingContainer({ children, sx }: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: '1 1 0',
        marginTop: '63px',
        ...sxToObject(sx, theme),
      }}>
      {children}
    </Box>
  );
}

export default BookingContainer;
