import { ServiceCategory } from '@prisma/client';
import API_URL from '../apiconfig';
import axios from './api';
import { getCurrentUserAuthorization } from './global';
import { AvailableTimes } from '../interfaces';
import { TakenSessionSlot } from '../types/appointments';

export async function getAvailableDays({
  startDate,
  endDate,
  duration,
  employeeId,
  timezone,
  businessId,
  onschedServiceId,
  serviceCategory,
  buffer,
  takenSlots,
}: {
  startDate: string;
  endDate: string;
  duration: number;
  employeeId: string;
  timezone: string;
  businessId?: string;
  onschedServiceId?: string | null;
  serviceCategory?: ServiceCategory;
  buffer?: {
    bufferDays: number;
    bufferDates: string[];
  };
  takenSlots?: TakenSessionSlot[];
}) {
  const authorization = await getCurrentUserAuthorization();
  let url = `${API_URL}/v1/availability`;
  if (!authorization) {
    url += '/public';
  }

  try {
    const response = await axios.get(url, {
      params: {
        by: 'time',
        startDate,
        endDate,
        duration,
        employeeId,
        timezone,
        onschedServiceId,
        businessId,
        serviceCategory,
        bufferDays: buffer?.bufferDays,
        bufferDates: buffer?.bufferDates
          ? JSON.stringify(buffer.bufferDates)
          : undefined,
        takenSlots: takenSlots ? JSON.stringify(takenSlots) : undefined,
      },
    });

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get available days');
  }
}

export async function getAvailableTimes({
  startDate,
  endDate,
  duration,
  employeeId,
  timezone,
  onschedServiceId,
  businessId,
  serviceCategory,
  takenSlots,
}: {
  startDate: string;
  endDate: string;
  duration: number;
  employeeId: string;
  timezone: string;
  onschedServiceId?: string | null;
  businessId?: string;
  serviceCategory?: ServiceCategory;
  takenSlots?: TakenSessionSlot[];
}) {
  const authorization = await getCurrentUserAuthorization();
  let url = `${API_URL}/v1/availability`;
  if (!authorization) {
    url += '/public';
  }

  try {
    const response = await axios.get(url, {
      params: {
        by: 'time',
        startDate,
        endDate,
        duration,
        employeeId,
        timezone,
        onschedServiceId,
        businessId,
        serviceCategory,
        takenSlots: takenSlots ? JSON.stringify(takenSlots) : undefined,
      },
    });

    return response.data.result as { availableTimes: AvailableTimes[] };
  } catch (error) {
    throw new Error('Cannot get available times');
  }
}
