import { useQuery } from '@tanstack/react-query';
import { differenceInMonths } from 'date-fns';
import useUser from '../global/useUser';
import { Employee, Service, ServiceFormValues } from '../../interfaces';
import { serviceFormSchema } from '../../utils/services';
import { createUpdateCustomer } from '../../services/customer';
import { createTemporaryServiceAppointment } from '../../services/appointment';
import { getTemporaryAppointmentKey } from '../../utils/appointment';
import { FullService } from '../../types/services';

interface CreateTemporaryServiceAppointmentOptions {
  service: Service | FullService;
  employee: Pick<Employee, 'employeeId'>;
  values: ServiceFormValues;
}

/**
 *
 * Creates a temporary service appointment.
 * This is used to allow processing payments for appointments prior to scheduling or confirming.
 *
 */
function useCreateTemporaryServiceAppointment({
  employee,
  service,
  values,
}: CreateTemporaryServiceAppointmentOptions) {
  const { user } = useUser();

  const { data, isLoading, isError, isFetching, error, refetch } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'temporary-service-appointment',
      user?.userId,
      employee?.employeeId,
      service?.serviceId,
      service?.minimumAgeInMonths,
      service?.defaultDepositPrice,
      values,
    ],
    queryFn: async () => {
      const validatedData = (await serviceFormSchema
        .noUnknown()
        .validate(values, { stripUnknown: true })) as ServiceFormValues;

      if (
        service!.minimumAgeInMonths &&
        service!.minimumAgeInMonths > 0 &&
        (!values.dob ||
          differenceInMonths(new Date(), new Date(values.dob)) <
            service!.minimumAgeInMonths)
      ) {
        throw new Error('MIN_AGE_NOT_MET');
      }

      await createUpdateCustomer(validatedData, user!).catch(() => {
        throw new Error('Failed to create/update customer');
      });

      const previousTemporaryAppointmentId = sessionStorage.getItem(
        getTemporaryAppointmentKey({
          employeeId: employee.employeeId,
          serviceId: service.serviceId,
          userId: user!.userId,
        }),
      );

      const appointmentResponse = await createTemporaryServiceAppointment({
        formValues: validatedData,
        employee,
        user: user!,
        service: service!,
        previousTemporaryAppointmentId,
      });

      if (appointmentResponse.appointmentId) {
        sessionStorage.setItem(
          getTemporaryAppointmentKey({
            employeeId: employee.employeeId,
            serviceId: service.serviceId,
            userId: user!.userId,
          }),
          appointmentResponse.appointmentId!,
        );
      }

      return appointmentResponse;
    },
    enabled: !!service && !!user && !!values && !!employee,
    staleTime: 0,
    gcTime: 0,
  });

  return {
    data,
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
  };
}

export default useCreateTemporaryServiceAppointment;
