/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { AppointmentStatus } from '@prisma/client';
import Typography from '@mui/material/Typography';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntentResult } from '@stripe/stripe-js';
import { CircularProgress, useTheme } from '@mui/material';
import { addMinutes, isSameMinute } from 'date-fns';
import { Appointment, Employee } from '../../../interfaces';
import PaymentTable from './PaymentTable';
import CheckoutForm from './CheckoutForm';
import {
  confirmAppointment,
  invalidateAppointment,
  invalidateAppointmentGroup,
  reschedule,
  reserveAppointment,
} from '../../../services/appointment';
import STRIPE_PK from '../../../stripe';
import LoadingModal from '../../Global/LoadingModal';
import BottomActionBar from '../../Global/BottomActionBar';
import useDepositCheckoutSession from '../../../hooks/payments/useDepositCheckoutSession';
import { validateDepositCheckout } from '../../../services/payments';
import { AppointmentGroupWithAppointments } from '../../../types/appointments';

const GENERIC_ERROR_MESSAGE =
  'An unexpected error occured scheduling this appointment. Please refresh the page and try again.';
const NOT_CHARGED_MESSAGE =
  'An unexpected error occured scheduling this appointment. You have not been charged. Please refresh the page and try again.';
const PAYMENT_EXPIRED_MESSAGE =
  'This payment is no longer available for checkout or is no longer valid. Please refresh the page and try again.';

interface Props {
  appointment: Appointment;
  appointmentGroup?: AppointmentGroupWithAppointments;
  appointmentsToDates: Record<string, Date | null>;
  status: 'schedule' | 'reschedule';
  employee: Employee;
  isMarketingOptIn?: boolean;
  isBack?: boolean;
  backAction?: () => void;
}

const stripePromise = loadStripe(STRIPE_PK);

const modalMessage =
  'This could take a minute. Please do not refresh the page.';
const modalHeader = 'Confirming appointment...';

export default function PaymentSection({
  appointment,
  appointmentGroup,
  appointmentsToDates,
  status,
  isBack,
  backAction,
  isMarketingOptIn,
  employee: _employee,
}: Props) {
  const theme = useTheme();
  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [inAppointmentExpired] = React.useState(false);

  const { checkoutSession, isFetching, isLoading, isError } =
    useDepositCheckoutSession({
      appointment,
      status,
    });
  const {
    isPaymentRequired,
    paymentIntent,
    depositCents = 0,
    salesTaxCents = 0,
    serviceFeeCents = 0,
  } = checkoutSession || {};

  const startDateTime = appointmentsToDates[appointment.appointmentId!]!;
  const dateValues = Object.values(appointmentsToDates).filter(
    Boolean,
  ) as Date[];

  const onConfirmationSuccess = (response: unknown) => {
    if (!response || !appointment?.appointmentId) return;

    window._cio.track('Schedule & Confirm', {
      appointment: appointment.appointmentId,
    });

    window.location.replace(`/booking/${appointment.appointmentId}`);
  };

  const onSubmit = async (
    confirmPayment?: () => Promise<PaymentIntentResult>,
  ) => {
    try {
      setSubmitting(true);
      setErrorMessage('');

      if (
        !appointment.appointmentId ||
        !appointment.appointmentLengthInMinutes ||
        !checkoutSession ||
        !dateValues.length
      ) {
        setErrorMessage(NOT_CHARGED_MESSAGE);
        return;
      }

      if (isPaymentRequired && !confirmPayment) {
        setErrorMessage(NOT_CHARGED_MESSAGE);
        return;
      }

      const isValid = await validateDepositCheckout({
        appointmentId: appointment.appointmentId,
        status,
        previousCheckoutSession: checkoutSession,
      });
      if (!isValid) {
        setErrorMessage(
          isPaymentRequired ? PAYMENT_EXPIRED_MESSAGE : GENERIC_ERROR_MESSAGE,
        );
        return;
      }

      if (status === 'schedule') {
        // If onschedID does not exist, or the initial appointment has expired, reserve the appointment in Onsched (appointment is accepted and user has not clicked confirm button yet)
        const appts = [appointment, ...(appointmentGroup?.appointments || [])];
        // since appointmentId -> date is not always maintained due to depositPlacement, check if all dates are reserved
        const areAllDatesReserved = dateValues.every(date =>
          appts.some(
            appt =>
              (appt.onschedId || appt.status === AppointmentStatus.SCHEDULED) &&
              appt.startDateTime &&
              isSameMinute(new Date(date), new Date(appt.startDateTime)),
          ),
        );
        if (!areAllDatesReserved || inAppointmentExpired) {
          // Set end date time based on start time and duration
          const dates = dateValues.map(date => ({
            startDateTime: date,
            endDateTime: addMinutes(
              date,
              appointment.appointmentLengthInMinutes || 0,
            ),
          }));

          // Reserve appointment in Onsched
          const { count } = await reserveAppointment(
            checkoutSession.appointmentId,
            dates,
          ).catch(() => {
            const tempErrorMessage =
              'This time is no longer available. Please choose a new date and time to schedule and confirm your appointment. You have not been charged.';
            setErrorMessage(tempErrorMessage);
            return { count: 0 };
          });

          if (count <= 0) throw Error('Reservation failed');
        }

        // Once onsched ID exists, process payment for this appointment
        if (isPaymentRequired) {
          const result = await confirmPayment!();
          if (result.error) {
            setErrorMessage(result.error.message || NOT_CHARGED_MESSAGE);
            throw Error('Payment failed');
          }
        }

        const confirmArgs = [
          appointment.appointmentId!,
          paymentIntent?.id,
          isMarketingOptIn,
        ] as const;

        const confirmResponse = await confirmAppointment(...confirmArgs).catch(
          async () => {
            // If first call fails, try to confirm again when the error is caught
            const newConfirmResponse = await confirmAppointment(
              ...confirmArgs,
            ).catch(() => {
              window.location.replace(`/booking/${appointment.appointmentId}`);
            });

            // If everything went through, refresh the booking page to pull new data
            if (newConfirmResponse) {
              onConfirmationSuccess(newConfirmResponse);
            }
          },
        );

        // If everything went through, refresh the booking page to pull new data
        if (confirmResponse) {
          onConfirmationSuccess(confirmResponse);
        }
      } else if (status === 'reschedule') {
        if (isPaymentRequired) {
          const result = await confirmPayment!();
          if (result.error) {
            setErrorMessage(result.error.message || NOT_CHARGED_MESSAGE);
            throw Error('Payment failed');
          }
        }

        // Set end date time based on start time and duration
        const endDateTime = addMinutes(
          startDateTime,
          appointment.appointmentLengthInMinutes!,
        );

        const depositPrice =
          appointment.depositPrice || appointment.reschedulePrice;
        const rescheduleResponse = await reschedule(
          startDateTime,
          endDateTime,
          appointment.appointmentId!,
          depositPrice,
        ).catch(() => {
          setErrorMessage(
            'Your payment was processed, but an unexpected error occured while rescheduling the appointment. Please reach out to our support team to finalize rescheduling your appointment',
          );
          throw Error('Reschedule failed');
        });

        // If everything went through, refresh the booking page to pull new data
        if (rescheduleResponse) {
          window.location.replace(`/booking/${appointment.appointmentId}`);
        }
      }
    } catch {
      invalidateAppointment();
      invalidateAppointmentGroup();
    } finally {
      setSubmitting(false);
    }
  };

  // Set up stripe appearance settings
  const appearance = {
    labels: 'floating' as 'floating',
    variables: {
      colorPrimary: theme.palette.primary.main,
      colorDanger: theme.palette.primary.main,
      colorBackground: '#ffffff',
      colorText: theme.palette.darkGrey.main,
      fontFamily: 'Montserrat',
      borderRadius: '5px',
      spacingUnit: '4px',
      fontSizeBase: '16px',
      fontWeightBold: '700',
      fontWeightLight: '400',
      colorTextPlaceholder: theme.palette.outlineGrey.main,
    },
    rules: {
      '.Label': {
        color: theme.palette.outlineGrey.main,
      },
      '.Input': {
        borderColor: theme.palette.outlineGrey.main,
      },
      '.Input:focus': {
        borderColor: theme.palette.darkGrey.main,
        boxShadow: 'none',
        outline: '0px',
        borderWidth: '1.5px',
      },
      '.Input--focused': {
        borderColor: theme.palette.darkGrey.main,
        boxShadow: 'none',
        outline: '0px',
        borderWidth: '1.5px',
      },
    },
  };

  const fonts = [
    { cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400' },
  ];

  const options = {
    clientSecret: paymentIntent?.client_secret || '',
    appearance,
    fonts,
  };

  if (isError) {
    return (
      <div className="payment-section">
        <Typography variant="body1" className="error">
          An error occurred loading the details. Please refresh the page and try
          again.
        </Typography>
      </div>
    );
  }

  return (
    <div className="payment-section">
      {isLoading && (
        <div className="loading-spinner">
          <CircularProgress
            sx={{
              marginTop: 5,
            }}
          />
        </div>
      )}

      {isPaymentRequired ? (
        <PaymentTable
          depositAmtCents={depositCents}
          serviceFeeCents={serviceFeeCents}
          salesTaxCents={salesTaxCents}
          status={status}
        />
      ) : null}

      {isPaymentRequired && !options?.clientSecret ? (
        <Typography align="center">Loading...</Typography>
      ) : null}

      {errorMessage ? (
        <Typography variant="body1" className="error" mt={2} px={2}>
          {errorMessage}
        </Typography>
      ) : null}

      {isPaymentRequired && options?.clientSecret ? (
        <Elements
          key={paymentIntent?.client_secret}
          stripe={stripePromise}
          options={options}>
          <CheckoutForm
            appointment={appointment}
            onSubmit={onSubmit}
            isSubmitting={submitting}
            onBack={isBack ? backAction : undefined}
          />
        </Elements>
      ) : null}

      {!isPaymentRequired && !submitting ? (
        <BottomActionBar
          primaryText="Confirm"
          primaryAction={() => onSubmit()}
          primaryDisabled={isFetching || submitting}
          secondaryDisabled={submitting}
          secondaryText={isBack ? 'Back' : undefined}
          secondaryAction={isBack ? backAction : undefined}
        />
      ) : null}

      {submitting && (
        <LoadingModal header={modalHeader} message={modalMessage} open />
      )}
    </div>
  );
}
