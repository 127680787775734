import API_URL from '../apiconfig';
import { FullService } from '../types/services';
import axios from './api';

export const getService = async (serviceId: number) => {
  try {
    const response = await axios.get(`${API_URL}/v1/services/${serviceId}`);

    return response.data.result as FullService;
  } catch (error) {
    throw new Error('Cannot get tattoo choices');
  }
};
