import { useQuery } from '@tanstack/react-query';
import { getAppointmentGroup } from '../../services/appointment';
import useUser from '../global/useUser';

function useGetAppointmentGroup(groupId: string | null | undefined) {
  const { user } = useUser();

  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
    isPlaceholderData,
  } = useQuery({
    queryKey: ['appointment-group', groupId, user?.userId],
    queryFn: async () => {
      const appointmentGroup = await getAppointmentGroup(groupId!);
      return appointmentGroup;
    },
    enabled: !!groupId && !!user?.userId,
  });

  return {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
    isPlaceholderData,
  };
}

export default useGetAppointmentGroup;
