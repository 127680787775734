import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import {
  Appointment,
  Business,
  Employee,
  TripStudio,
} from '../../../interfaces';
import '../Booking.css';
import { cancel, createAppointmentChat } from '../../../services/appointment';
import LoadingModal from '../../Global/LoadingModal';
import EmployeeHeader from '../../EmployeeHeader/EmployeeHeader';
import BottomActionBar from '../../Global/BottomActionBar';
import AppointmentDetailsList from '../General/AppointmentDetailsList';
import ConfirmationModal from '../../Global/ConfirmationModal';
import { AppointmentGroupWithAppointments } from '../../../types/appointments';
import { navigateToChat } from '../../../utils/chats';

type Props = {
  appointment: Appointment;
  appointmentGroup?: AppointmentGroupWithAppointments;
  employee: Employee;
  business: Business | undefined;
};

function AppointmentPage({
  appointment,
  appointmentGroup,
  employee,
  business,
}: Props) {
  const [error, setError] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [cancelModal, setCancelModal] = React.useState(false);
  const [tripStudio, setTripStudio] = React.useState<TripStudio>();

  React.useEffect(() => {
    if (
      employee &&
      employee.trips &&
      employee.trips.length > 0 &&
      appointment &&
      appointment.tripCity
    ) {
      const trip = employee.trips.find(
        tripItem => tripItem.city === appointment.tripCity,
      );
      if (trip) {
        const tempTripStudio = {
          studioName: trip.studioName,
          studioAddress: trip.streetAddress,
          studioAddress2: trip.streetAddress2,
          studioCity: trip.city,
          studioState: trip.state,
          studioZip: trip.zip,
        };

        setTripStudio({ ...tempTripStudio });
      }
    }
  }, [appointment, employee]);

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Canceling appointment...';

  const cancelAppointment = async () => {
    setSubmitting(true);
    setModalOpen(true);

    if (!appointment.appointmentId) {
      setError(true);
      setSubmitting(false);
      setModalOpen(false);
      return;
    }

    // Cancel the appointment
    await cancel(appointment.appointmentId)
      .then(() => {
        window.location.replace(`/booking/${appointment.appointmentId}`);
      })
      .catch(() => {
        setError(true);
        setModalOpen(false);
        setSubmitting(false);
      });
  };

  const openCancelModal = () => {
    setCancelModal(true);
  };

  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const openChat = React.useCallback(async () => {
    if (appointment) {
      const chatObject = await createAppointmentChat(appointment);
      navigateToChat(chatObject);
    }
  }, [appointment]);

  const isConsultation = appointment.appointmentType === 'CONSULTATION';
  const canReschedule =
    employee.reschedulePolicy !== 'CLOSED' && !isConsultation;

  return (
    <Box
      style={{
        flex: '1 1 0',
        marginTop: 63,
        paddingBottom: 165,
      }}>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          Your {isConsultation ? 'consultation' : 'appointment'} with{' '}
          {employee.displayName || employee?.user.firstName}
        </Typography>
      </Box>

      <EmployeeHeader
        employee={employee}
        business={business ?? employee.business}
        tripStudio={tripStudio}
      />

      {appointment.previousStatus === 'SCHEDULED' ? (
        <AppointmentDetailsList
          appointment={appointment}
          employee={employee}
          business={business}
          aptDate={new Date(appointment.startDateTime!)}
          changeDateAction={openCancelModal}
          changeDateText="Cancel appointment"
          location
          appointmentDate
          price
          cancellation
        />
      ) : (
        <AppointmentDetailsList
          appointment={appointment}
          appointmentGroup={appointmentGroup}
          employee={employee}
          business={business}
          aptDate={new Date(appointment.startDateTime!)}
          changeDateAction={openCancelModal}
          changeDateText="Cancel appointment"
          location
          appointmentDate
          designMessage={
            appointment.serviceCategory === 'TATTOO' && !isConsultation
          }
          price
          cancellation={!isConsultation}
          showConfirmedAppointments
          isScheduleGroupRest
        />
      )}

      {!canReschedule ? (
        <BottomActionBar primaryText="Chat" primaryAction={openChat} />
      ) : (
        <BottomActionBar
          primaryText="Chat"
          primaryAction={openChat}
          secondaryText="Reschedule"
          secondaryAction={() => {
            window.location.href = `/reschedule/${appointment.appointmentId}`;
          }}
        />
      )}

      {submitting && (
        <LoadingModal
          header={modalHeader}
          message={modalMessage}
          open={modalOpen}
        />
      )}

      {error ? (
        <Typography variant="body1" className="error">
          There was an error cancelling your appointment. Please refresh the
          page and try again. If the error persists, reach out to our support
          team through the help icon above.
        </Typography>
      ) : null}

      <ConfirmationModal
        open={cancelModal}
        setOpen={setCancelModal}
        title="Are you sure you want to cancel"
        message="If you cancel your appointment, you won't receive any deposit you've already paid back, and you'll need to create a new appointment if you want to schedule it again"
        negativeAction={closeCancelModal}
        negativeText="Back"
        positiveAction={cancelAppointment}
        positiveText="Yes, Cancel"
      />
    </Box>
  );
}

export default AppointmentPage;
