import React from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Talk from 'talkjs';
import { format } from 'date-fns';
import { useSession } from '@talkjs/react';
import { GeneralRequest } from '../../interfaces';
import useGetChatHeaderAppointment from '../../hooks/chat/useGetChatHeaderAppointment';
import useGetBusinessById from '../../hooks/businesses/useGetBusinessById';
import { getAppointmentStatus } from '../../utils/appointment';
import { important } from '../../utils/global';
import useCleanParam from '../../hooks/useCleanParam';
import { URL_FRIENDLY } from '../../constants/regex';

interface ChatBoxHeaderProps {
  selectedConversation: Talk.ConversationData | null;
  onSelectConversation: (conversation: Talk.ConversationData | null) => void;
}

function ChatBoxHeader({
  selectedConversation,
  onSelectConversation,
}: ChatBoxHeaderProps) {
  const conversationId = useCleanParam({
    key: 'conversationId',
    regex: URL_FRIENDLY,
  });
  const [headerText, setHeaderText] = React.useState('');
  const [statusText, setStatusText] = React.useState('');
  const [buttonText, setButtonText] = React.useState('');

  const session = useSession();
  const { appointment, appointmentIdFromParam } =
    useGetChatHeaderAppointment(selectedConversation);
  const { business } = useGetBusinessById(appointment?.businessId);

  React.useEffect(() => {
    if (!appointment) return;
    const employeeName =
      appointment.employee?.displayName || appointment.employee?.firstName;
    const status = getAppointmentStatus(appointment);
    if (status === 'REQUESTED') {
      const requestedOn = new Date(appointment.createdAt || '');
      const requestedText = format(requestedOn, 'MM/dd/yyyy');
      setHeaderText(`Request with ${employeeName}`);
      setStatusText(`Requested on ${requestedText}`);
      setButtonText('View request');
    } else if (status === 'ACCEPTED') {
      const acceptedOn = new Date(appointment.updatedAt || '');
      const acceptedText = format(acceptedOn, 'MM/dd/yyyy');
      setHeaderText(`Request with ${employeeName}`);
      setStatusText(`Accepted on ${acceptedText}`);
      setButtonText('Schedule & Confirm');
    } else if (status === 'REJECTED') {
      const acceptedOn = new Date(appointment.updatedAt || '');
      const acceptedText = format(acceptedOn, 'MM/dd/yyyy');
      setHeaderText(`Request with ${employeeName}`);
      setStatusText(`Rejected on ${acceptedText}`);
      setButtonText('View request');
    } else if (status === 'CONFIRMED') {
      const scheduledOn = new Date(appointment.startDateTime || '');
      const scheduledText = format(scheduledOn, 'MM/dd/yyyy');
      setHeaderText(`Appointment with ${employeeName}`);
      setStatusText(`Scheduled on ${scheduledText}`);
      setButtonText('View appointment');
    } else if (status === 'SCHEDULED') {
      const scheduledOn = new Date(appointment.startDateTime || '');
      const scheduledText = format(scheduledOn, 'MM/dd/yyyy');
      setHeaderText(`Appointment with ${employeeName}`);
      setStatusText(`Scheduled on ${scheduledText}`);
      setButtonText('Confirm appointment');
    } else if (status === 'CANCELED') {
      const acceptedOn = new Date(appointment.updatedAt || '');
      const acceptedText = format(acceptedOn, 'MM/dd/yyyy');
      setHeaderText(`Request with ${employeeName}`);
      setStatusText(`Canceled on ${acceptedText}`);
      setButtonText('View appointment');
    } else if ((appointment as GeneralRequest).requestId) {
      const requestedOn = new Date(appointment.createdAt || '');
      const requestedText = format(requestedOn, 'MM/dd/yyyy');
      setHeaderText(`Request with ${business?.name}`);
      setStatusText(`Requested on ${requestedText}`);
      setButtonText('View request');
    }
  }, [appointment, business]);

  const backToInbox = React.useCallback(() => {
    if (
      conversationId &&
      appointment &&
      (appointment.appointmentId === conversationId ||
        appointment.appointmentId === appointmentIdFromParam)
    ) {
      window.location.href = `/booking/${appointment.appointmentId}`;
    } else if (session?.isAlive) {
      onSelectConversation(null);
    }
  }, [
    conversationId,
    appointment,
    appointmentIdFromParam,
    session,
    onSelectConversation,
  ]);

  if (!selectedConversation) return null;

  return (
    <div className="message-header">
      {appointment && (
        <>
          <div className="chat-nav">
            <Button onClick={backToInbox} className="chat-back">
              <ChevronLeftIcon />
              {appointment.employee ? (
                <Typography variant="h2">
                  {appointment.employee?.displayName ||
                    appointment.employee?.firstName}
                </Typography>
              ) : (
                <Typography variant="h2">{business?.name}</Typography>
              )}
            </Button>
          </div>
          <div className="chat-appointment-box">
            {appointment.refImgsId && appointment.refImgsId.length > 0 ? (
              <Avatar alt="appointment image" src={appointment.refImgsId[0]} />
            ) : (
              <Avatar
                alt="appointment image"
                src="https://uploads-ssl.webflow.com/62b4bc5a9e85d037dbfff77c/62b4bf47f7e8c4164e419a4c_maxim-hopman-52Kf36w124Y-unsplash%20(1).jpg"
              />
            )}
            <div className="chat-appointment-text">
              <Typography variant="h3">{headerText}</Typography>
              <Typography variant="body2">{statusText}</Typography>
              {!appointment.checkoutSessionCompleted && (
                <Button
                  variant="outlined"
                  style={{
                    marginTop: 10,
                    fontWeight: 400,
                    marginLeft: 0,
                    float: 'left',
                  }}
                  href={`/booking/${
                    appointment.appointmentId ||
                    (appointment as GeneralRequest).requestId
                  }`}>
                  {buttonText}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {!appointment && (
        <Box
          className="chat-appointment-box"
          py={theme => important(theme.spacing(2))}
          px={theme => important(theme.spacing(1))}>
          <Button
            onClick={backToInbox}
            sx={{
              flexShrink: 0,
              p: 0,
            }}>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <ChevronLeftIcon />
              <Avatar
                sx={{
                  alignSelf: 'start',
                }}
                alt="appointment image"
                src="https://uploads-ssl.webflow.com/62b4bc5a9e85d037dbfff77c/62b4bf47f7e8c4164e419a4c_maxim-hopman-52Kf36w124Y-unsplash%20(1).jpg"
              />
            </Stack>
          </Button>
          <Stack className="chat-appointment-text" gap={1}>
            <Typography variant="h3">
              Chat with: {selectedConversation?.custom?.entity_name || ''}
            </Typography>
            <Button
              variant="outlined"
              style={{
                fontWeight: 400,
                marginLeft: 0,
                width: 'fit-content',
              }}
              href="/booking">
              View bookings
            </Button>
          </Stack>
        </Box>
      )}
    </div>
  );
}

export default ChatBoxHeader;
