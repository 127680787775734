import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { Business, Service, ServiceFormValues } from '../../../interfaces';
import ArtistRow from '../../BusinessHeader/ArtistRow';
import PageLayout from '../../Global/PageLayout';
import PageError from '../../Global/Error';
import { FullService } from '../../../types/services';

type Props = {
  nextStep: () => void;
  prevStep?: () => void;
  service: Service | FullService;
  business: Business;
};

function ChooseProvider({ nextStep, prevStep, service, business }: Props) {
  const formik = useFormikContext<ServiceFormValues>();

  const employeeIdsInService =
    service.employeesToServices?.map(es => es.employeeId) || [];

  const employees =
    business.employees?.filter(emp =>
      employeeIdsInService.includes(emp.employeeId),
    ) || [];

  const questionValues = {
    header: 'Choose your provider',
  };

  const handleClick = (employeeId: string) => {
    if (employeeId) {
      formik.setFieldValue('employeeId', employeeId);
      nextStep();
    }
  };

  if (!employees.length) {
    return (
      <PageError message="No providers found for this service. Please refresh the page and try again, or select a different service" />
    );
  }

  return (
    <PageLayout header={questionValues.header} onBack={prevStep}>
      <Stack spacing={2} mx={2}>
        {employees.map(employee => (
          <ArtistRow
            key={employee.employeeId}
            employee={employee}
            handleClick={handleClick}
          />
        ))}
      </Stack>
    </PageLayout>
  );
}

export default ChooseProvider;
