import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import useWaiver from '../../../hooks/waiver/useWaiver';
import { Appointment, Business } from '../../../interfaces';
import ConfirmCustomerDetails from './ConfirmCustomerDetails';
import StaticLiabilityWaiver from './StaticLiabilityWaiver';
import Acknowledgments from './Acknowledgments';
import MedicalHistory from './MedicalHistory';
import IDUpload from './IDUpload';
import CompleteAndSign from './CompleteAndSign';
import { WaiverForm, WaiverSectionProps } from '../../../types/waivers';
import { getInitialWaiverForm, storeWaiverForm } from '../../../utils/waivers';
import useUser from '../../../hooks/global/useUser';
import useSubmitWaiver from '../../../hooks/waiver/useSubmitWaiver';
import { FormHeaderAlert } from '../../Form/FormHeader';
import useIOSVersion from '../../../hooks/global/useIOSVersion';
import CompletedWaiverRequest from './CompletedWaiverRequest';
import PageLayout from '../../Global/PageLayout';

type Props = {
  business: Business | undefined;
  onSuccess?: () => void | Promise<void>;
  handleBack?: () => void;
} & (
  | {
      type: 'appointment';
      appointment: Appointment | undefined;
    }
  | {
      type: 'preview';
    }
  | {
      type: 'session';
      sessionId: string;
    }
);

interface WaiverStep {
  component: React.ComponentType<WaiverSectionProps>;
}

const PREVIEW_ALERT: FormHeaderAlert = {
  title: 'This is a waiver preview',
  subtitle:
    "Do not share this link with customers - this is just intended for you to preview the waiver. Customers will be sent their own, personalized waiver link when it's time for them to complete a waiver.",
  type: 'info',
  dismissible: true,
  animate: false,
  persistentDismissAlertKey: 'waiver-preview-alert',
};

const SESSION_EXPIRED_ERROR =
  'Your session to submit this waiver on this device has expired. If you are the employee, please re-submit your request to submit the waiver from this device.';

export default function Waiver({
  business,
  onSuccess,
  handleBack,
  ...props
}: Props) {
  const iosVersion = useIOSVersion();

  const [step, setStep] = React.useState(0);
  const { user } = useUser();

  const initialAppointment =
    props.type === 'appointment' ? props.appointment : undefined;
  const isPreview = props.type === 'preview';
  const sessionId = props.type === 'session' ? props.sessionId : undefined;
  const isSession = props.type === 'session' && !!sessionId;
  const isValidUser = !!user || isPreview || isSession;

  const {
    waiver,
    previewWaiverId,
    appointment,
    sessionWaiverRequest,
    session,
    isError,
    error,
    waiverType,
  } = useWaiver({
    appointment: initialAppointment,
    isPreview,
    sessionId,
  });
  const waiverRequest = appointment?.waiverRequest ?? sessionWaiverRequest;
  const isSessionExpired =
    waiverType === 'session' && (error as AxiosError)?.response?.status === 403;
  const isRequestCanceled = waiverRequest?.status === 'CANCELED';

  if (iosVersion && iosVersion.major < 17) {
    // @ts-ignore
    if (typeof Promise.withResolvers === 'undefined') {
      if (window)
        // @ts-expect-error This does not exist outside of polyfill which this is doing
        window.Promise.withResolvers = () => {
          let resolve;
          let reject;
          const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
          });
          return { promise, resolve, reject };
        };
    }
  }

  const handleSuccess = onSuccess ?? (() => window.location.reload());

  const { submitWaiverMutation } = useSubmitWaiver({
    appointment,
    waiverRequest: appointment?.waiverRequest ?? sessionWaiverRequest,
    sessionId,
    business,
    onSuccess: handleSuccess,
    onSessionExpiredError: () => {
      toast.error(SESSION_EXPIRED_ERROR);
    },
  });

  const [waiverForm, setWaiverForm] = React.useState<WaiverForm | null>(null);

  const storageIdentifier = isPreview
    ? `${previewWaiverId}-preview`
    : isSession
    ? `${sessionId}-session`
    : appointment?.appointmentId;

  React.useEffect(() => {
    const getWaiverForm = async () => {
      if (waiver && isValidUser && !waiverForm && storageIdentifier) {
        setWaiverForm(
          getInitialWaiverForm(
            user,
            waiver,
            storageIdentifier,
            isPreview,
            isSession,
          ),
        );
      }
    };
    getWaiverForm();
  }, [
    waiver,
    user,
    waiverForm,
    storageIdentifier,
    isPreview,
    isSession,
    sessionId,
    isValidUser,
  ]);

  React.useEffect(() => {
    if (waiver && isValidUser && waiverForm && storageIdentifier) {
      storeWaiverForm(user, waiver, storageIdentifier, waiverForm, isSession);
    }
  }, [
    waiver,
    user,
    waiverForm,
    storageIdentifier,
    isPreview,
    isValidUser,
    isSession,
  ]);

  const steps = [
    { component: ConfirmCustomerDetails },
    waiver?.staticWaiverSettings?.enabled && {
      component: StaticLiabilityWaiver,
    },
    waiver?.acknowledgmentSettings?.enabled && { component: Acknowledgments },
    waiver?.medicalHistorySettings?.enabled && { component: MedicalHistory },
    waiver?.identificationSettings?.enabled && { component: IDUpload },
    { component: CompleteAndSign },
  ].filter(Boolean) as WaiverStep[];

  const nextStep = () => {
    if (!waiver) return;
    setStep(prev => {
      const newStep = Math.min(prev + 1, steps.length - 1);
      if (newStep !== prev) {
        window.scrollTo(0, 0);
      }
      return newStep;
    });
  };
  const prevStep = () => setStep(prev => Math.max(prev - 1, 0));

  const onSubmit = async (finalSignature: string) => {
    if (!waiverForm) return;
    if (
      (appointment && props.type === 'appointment') ||
      (props.type === 'session' && sessionId)
    ) {
      await submitWaiverMutation.mutateAsync({
        ...waiverForm,
        signature: finalSignature,
      });
    }
    if (isPreview) {
      toast.success('Preview waiver completed!');
    }
  };

  const CurrentStep = steps[step]?.component;

  if (isSessionExpired || isError || isRequestCanceled) {
    return (
      <PageLayout>
        <Box py={4}>
          <Typography variant="h1">
            {isSessionExpired
              ? SESSION_EXPIRED_ERROR
              : isRequestCanceled
              ? 'This waiver request has been canceled and no longer needs to be submitted.'
              : 'An error occurred while loading the waiver. Please try again.'}
          </Typography>
        </Box>
      </PageLayout>
    );
  }

  if (
    !waiverForm ||
    !waiver ||
    (!appointment && !isPreview && !sessionId) ||
    !business
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={300}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (isSession && session && sessionWaiverRequest?.status === 'SIGNED') {
    return <CompletedWaiverRequest />;
  }

  return (
    <Box>
      <CurrentStep
        alert={isPreview ? PREVIEW_ALERT : undefined}
        isPreview={isPreview}
        isSession={isSession}
        employee={{
          firstName: isPreview
            ? 'John'
            : appointment?.employee?.firstName ?? '',
          lastName: isPreview
            ? 'Doe (Artist)'
            : appointment?.employee?.lastName ?? '',
        }}
        business={business}
        waiver={waiver}
        onNext={nextStep}
        onBack={step === 0 ? handleBack : prevStep}
        onSubmit={onSubmit}
        waiverForm={{
          value: waiverForm,
          set: setWaiverForm as React.Dispatch<
            React.SetStateAction<WaiverForm>
          >,
        }}
      />
    </Box>
  );
}
