import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { auth, storage } from '../firebase';

interface ImageInput {
  id: string;
  url: string;
  path: string;
}

export const getCurrentUserAuthorization = async () => {
  const { currentUser } = auth;
  if (currentUser) {
    const token = await currentUser.getIdToken();
    // console.log(token);
    return `Bearer ${token}`;
  }
  return '';
};

export const uploadFiles = async (
  images: ImageInput[],
  {
    onSuccess,
    isPublicUpload = false,
  }: {
    onSuccess?: (image: ImageInput) => void;
    isPublicUpload?: boolean;
  } = {},
) => {
  const user = auth.currentUser;
  const authServiceId = user?.uid;
  if (!authServiceId && !isPublicUpload) throw new Error('Not authenticated');

  const transferPromises: Promise<string>[] = [];

  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];

    const response = await fetch(image.url as URL | RequestInfo);
    const blob = await response.blob();

    transferPromises.push(
      new Promise((resolve, reject) => {
        const storageUrl = image.path;
        const permanentImageRef = ref(storage, storageUrl);
        const uploadTask = uploadBytesResumable(permanentImageRef, blob);
        uploadTask.on(
          'state_changed',
          () => {},
          uploadError => reject(uploadError),
          async () => {
            onSuccess?.(image);
            const downloadURL = await getDownloadURL(permanentImageRef);
            resolve(downloadURL);
          },
        );
      }),
    );
  }

  const imgUrls = await Promise.all(transferPromises);
  return imgUrls;
};
