import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import {
  Appointment,
  Business,
  DetailItem,
  Employee,
  TripStudio,
  User,
} from '../../../interfaces';
import '../Booking.css';
import DetailList from '../Appointment/DetailList';
import {
  createRequestOverview,
  getAppointmentStatus,
} from '../../../utils/appointment';
import EmployeeHeader from '../../EmployeeHeader/EmployeeHeader';
import { getChoices } from '../../../services/choices';
import { createAppointmentChat } from '../../../services/appointment';
import BottomActionBar from '../../Global/BottomActionBar';
import { navigateToChat } from '../../../utils/chats';

type Props = {
  appointment: Appointment;
  employee: Employee | undefined;
  business: Business | undefined;
  user: User;
};

// TODO - Add images to review submission page
function Overview({ appointment, employee, business, user }: Props) {
  const [tripStudio, setTripStudio] = React.useState<TripStudio>();
  const [detailItems, setDetailItems] = React.useState<DetailItem[]>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      const tempSizeOptions = await getChoices('TATTOO_SIZE').catch(() => {});
      const tempDetailItems = createRequestOverview(
        user,
        appointment,
        tempSizeOptions,
      );

      setDetailItems(tempDetailItems);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  }, [appointment, loading, user]);

  React.useEffect(() => {
    if (
      employee &&
      employee.trips &&
      employee.trips.length > 0 &&
      appointment &&
      appointment.tripCity
    ) {
      const trip = employee.trips.find(
        tripItem => tripItem.city === appointment.tripCity,
      );
      if (trip) {
        const tempTripStudio = {
          studioName: trip.studioName,
          studioAddress: trip.streetAddress,
          studioAddress2: trip.streetAddress2,
          studioCity: trip.city,
          studioState: trip.state,
          studioZip: trip.zip,
        };

        setTripStudio({ ...tempTripStudio });
      }
    }
  }, [appointment, employee]);

  const status = getAppointmentStatus(appointment);

  let name = business?.name || '';
  if (employee) {
    name = employee.displayName
      ? employee.displayName
      : `${employee.user.firstName} ${employee.user.lastName}`;
  }

  const requestedTitle = `Your request was sent!`;
  const requestedSub = `You'll get a text message to schedule your appointment when ${name} replies.`;

  const rejectedTitle = `Your booking request was rejected by ${name}`;
  const canceledTitle = `Your appointment with ${name} was canceled`;

  const completedTitle = `Your appointment is complete!`;
  const completedSub = `Your payment was processed and this appointment is completed.`;

  const archivedTitle = `Your request was archived`;
  const archivedSub = `Reach out to your artist if you beleive this is an error`;

  let title = '';
  let sub = '';
  if (status === 'REQUESTED') {
    title = requestedTitle;
    sub = requestedSub;
  } else if (status === 'REJECTED') {
    title = rejectedTitle;
  } else if (status === 'CANCELED') {
    title = canceledTitle;
  } else if (
    (status === 'CONFIRMED' || status === 'SCHEDULED') &&
    appointment.checkoutSessionCompleted
  ) {
    title = completedTitle;
    sub = completedSub;
  } else if (status === 'ARCHIVED') {
    title = archivedTitle;
    sub = archivedSub;
  } else {
    title = requestedTitle;
    sub = requestedSub;
  }

  const openChat = React.useCallback(async () => {
    if (appointment) {
      const chatObject = await createAppointmentChat(appointment);
      navigateToChat(chatObject);
    }
  }, [appointment]);

  return (
    <Box
      style={{
        flex: '1 1 0',
        marginTop: 63,
        paddingBottom: 165,
      }}>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {title}
        </Typography>
        {sub && (
          <Typography variant="body2" className="form-sub-header">
            {sub}
          </Typography>
        )}
      </Box>

      <EmployeeHeader
        employee={employee}
        business={business ?? employee?.business}
        tripStudio={tripStudio}
      />

      {detailItems && <DetailList detailItems={detailItems} />}

      <BottomActionBar
        primaryText={`Chat with ${employee?.displayName || 'your artist'}`}
        primaryAction={openChat}
      />
    </Box>
  );
}

export default Overview;
