import * as yup from 'yup';
import {
  AppointmentGroups,
  Appointments,
  Customers,
  GeneralRequests,
  Users,
} from '@prisma/client';
import { isDate } from 'date-fns';
import { Appointment } from '../../interfaces';
import { ValueOf } from '../global';
import { getRequiredAppointmentsForConfirm } from '../../utils/appointment';

export type GeneralRequestWithCustomers = GeneralRequests & {
  customers: Customers & { user: Customers & Users };
};

export type AppointmentGroupWithAppointments = AppointmentGroups & {
  appointments: Appointments[];
};

export const getScheduleAcceptedAppointmentsSchema = (
  appointment: Appointments | Appointment,
  appointmentGroup: AppointmentGroupWithAppointments | undefined,
) => {
  const requiredAppointments = getRequiredAppointmentsForConfirm(
    appointment,
    appointmentGroup,
  );

  return yup.object({
    appointmentsToDates: yup
      .mixed<Record<string, Date>>()
      .test(
        'required-appointments',
        'Required appointments are missing',
        // eslint-disable-next-line func-names
        function () {
          const { appointmentsToDates: value } = this.parent;
          if (typeof value !== 'object' || value === null) {
            return false;
          }
          return requiredAppointments.every(appt => {
            const apptValue = value[appt.appointmentId!];
            return apptValue && isDate(apptValue);
          });
        },
      )
      .required(),
  });
};

export type ScheduleAcceptedAppointments = yup.InferType<
  ReturnType<typeof getScheduleAcceptedAppointmentsSchema>
>;

export type ScheduleAndConfirmStep = 'details' | 'dateTime' | 'confirm';

export type ScheduleAndConfirmState = {
  step: ScheduleAndConfirmStep;
  selectedDay: Date;
  selectedAppointmentId: string;
  appointmentsToDates: Record<string, Date | null>;
};

export type ScheduleAndConfirmAction =
  | {
      type: 'step';
      payload: ScheduleAndConfirmState['step'];
    }
  | {
      type: 'selectedDay';
      payload: ScheduleAndConfirmState['selectedDay'];
    }
  | {
      type: 'selectedAppointmentId';
      payload: ScheduleAndConfirmState['selectedAppointmentId'];
    }
  | {
      type: 'appointmentsToDates';
      payload: {
        appointmentId: string;
        date: ValueOf<ScheduleAndConfirmState['appointmentsToDates']>;
      };
    }
  | {
      type: 'setAppointmentsToDates';
      payload: ScheduleAndConfirmState['appointmentsToDates'];
    };

export type DateAction = (params: { appointmentId: string }) => void;

export interface TakenSessionSlot {
  startDateTime: string;
  endDateTime: string;
}
