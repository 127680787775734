import { useQuery } from '@tanstack/react-query';
import useUser from '../global/useUser';
import { getConversationAppointment } from '../../services/chats';

interface Options {
  enabled?: boolean;
}

function useGetConversationAppointment(
  conversationId: string | null | undefined,
  appointmentId: string | null | undefined,
  { enabled = true }: Options = {},
) {
  const { user } = useUser();

  const isEnabled =
    !!conversationId && !!appointmentId && !!user?.userId && enabled;

  const {
    data,
    isPlaceholderData,
    isFetching,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      'chat-conversation-appointment',
      user?.userId,
      conversationId,
      appointmentId,
    ],
    queryFn: async () => {
      const result = await getConversationAppointment(
        conversationId!,
        appointmentId!,
      );
      return result;
    },
    enabled: isEnabled,
  });

  return {
    data,
    isPlaceholderData,
    isFetching,
    isLoading,
    isError,
    error,
    refetch,
    isEnabled,
  };
}

export default useGetConversationAppointment;
