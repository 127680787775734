import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function useCleanParam({ key, regex }: { key: string; regex: RegExp }) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = useParams();
  const value = params[key];

  const cleanedValue = useMemo(() => value?.replace(regex, ''), [value, regex]);
  useEffect(() => {
    if (cleanedValue && value && cleanedValue !== value) {
      const cleanedPath = pathname.replace(value, cleanedValue);
      const newPath = `${cleanedPath}${search}`.trim();
      navigate(newPath, {
        replace: true,
        preventScrollReset: true,
      });
    }
  }, [cleanedValue, key, navigate, pathname, search, value]);

  return cleanedValue;
}

export default useCleanParam;
