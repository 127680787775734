import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues, FullIntakeQuestion } from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  otherNotes: yup.string().optional().nullable(),
});

function OtherNotes({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  intakeQuestion,
}: Props) {
  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle ||
      "Any final notes you'd like to add before you submit?",
    placeholder: intakeQuestion?.customQuestionPlaceholder || 'Type here...',
  };

  const formik = useFormik({
    initialValues: {
      otherNotes: formValues.otherNotes,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      if (values.otherNotes) {
        sessionStorage.setItem('otherNotes', values.otherNotes!);
      }
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="other-notes">
      <FormHeader header={questionValues.header} />
      <Box className="input-container">
        <TextField
          id="otherNotes"
          name="otherNotes"
          value={formik.values.otherNotes}
          onChange={formik.handleChange}
          error={formik.touched.otherNotes && Boolean(formik.errors.otherNotes)}
          helperText={formik.touched.otherNotes && formik.errors.otherNotes}
          multiline
          placeholder={questionValues.placeholder}
          minRows={3}
          className="textarea"
        />
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default OtherNotes;
