import { useQuery } from '@tanstack/react-query';
import { ConversationData } from 'talkjs/all';
import { getConversation } from '../../services/chats';
import useUser from '../global/useUser';

interface Options {
  enabled?: boolean;
  onSuccess?: (conversation: ConversationData) => void;
}

function useGetConversation(
  conversationId: string | null | undefined,
  { enabled = true, onSuccess }: Options = {},
) {
  const { user } = useUser();

  const isEnabled = !!conversationId && !!user?.userId && enabled;

  const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
    queryKey: ['chat-conversation', user?.userId, conversationId],
    queryFn: async () => {
      const result = await getConversation(conversationId!);
      onSuccess?.(result);
      return result;
    },
    enabled: isEnabled,
  });

  return {
    conversation: data,
    isFetching,
    isLoading,
    isError,
    error,
    refetch,
    isEnabled,
  };
}

export default useGetConversation;
