/* eslint-disable react/require-default-props */
import '../Booking.css';
import { Box, Typography } from '@mui/material';
import { Employee, Appointment, Business, Service } from '../../../interfaces';
import AppointmentDetailsList from '../General/AppointmentDetailsList';
import {
  AppointmentGroupWithAppointments,
  DateAction,
} from '../../../types/appointments';
import ConfirmActionSection from './ConfirmActionSection';
import { FullService } from '../../../types/services';

type Props = {
  service?: Service | FullService;
  appointment: Appointment;
  appointmentGroup?: AppointmentGroupWithAppointments;
  appointmentsToDates: Record<string, Date | null>;
  employee: Employee;
  business: Business | undefined;
  status: 'schedule' | 'reschedule';
  isMarketingOptIn?: boolean;
  isBack?: boolean;
  backAction?: () => void;
  changeDateAction?: DateAction;
  removeDateAction?: DateAction;
  isReschedule?: boolean;
  isScheduleAdditional?: boolean;
};

function Confirm({
  service,
  appointment,
  appointmentGroup,
  appointmentsToDates,
  employee,
  business,
  status,
  isMarketingOptIn,
  isBack,
  backAction,
  isReschedule,
  isScheduleAdditional,
  changeDateAction,
  removeDateAction,
}: Props) {
  let title = '';
  if (status === 'reschedule') {
    title = `Reschedule Your Appointment`;
  } else {
    title = `Confirm your appointment`;
  }

  return (
    <Box>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {title}
        </Typography>
      </Box>

      <AppointmentDetailsList
        service={service}
        serviceType={!!service}
        appointment={appointment}
        appointmentGroup={appointmentGroup}
        employee={employee}
        business={business}
        appointmentsToDates={appointmentsToDates}
        location
        appointmentDate
        price
        cancellation
        changeDateAction={changeDateAction}
        changeDateText="Choose a different date"
        removeDateAction={removeDateAction}
        isReschedule={isReschedule}
        isScheduleAdditional={isScheduleAdditional}
        showConfirmedAppointments
      />

      <ConfirmActionSection
        appointment={appointment}
        appointmentGroup={appointmentGroup}
        appointmentsToDates={appointmentsToDates}
        status={status}
        employee={employee}
        isBack={isBack}
        backAction={backAction}
        changeDateAction={changeDateAction}
        isMarketingOptIn={isMarketingOptIn}
      />
    </Box>
  );
}

export default Confirm;
