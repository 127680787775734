/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import PorterFooter from '../components/Global/PorterFooter';
import TopBar from '../components/Global/TopBar';
import useGetBusiness from '../hooks/businesses/useGetBusiness';
import LoadingIndicator from '../components/Global/LoadingIndicator';
import ServiceBookingForm from '../components/Form/ServiceBooking/ServiceBookingForm';
import useGetServiceFromParam from '../hooks/services/useGetServiceFromParam';
import PageError from '../components/Global/Error';

export default function Service() {
  const {
    business,
    isLoading: isLoadingBusiness,
    isError: isErrorBusiness,
  } = useGetBusiness();
  const {
    service,
    isLoading: isServiceLoading,
    isError: isServiceError,
  } = useGetServiceFromParam();
  const trackedPageView = useRef(false);

  const isLoading = isLoadingBusiness || isServiceLoading;
  const isError = isErrorBusiness || isServiceError;

  useEffect(() => {
    if (business && service && !trackedPageView.current) {
      window._cio.page('Service', {
        shopName: business.name,
        serviceName: service.serviceName,
      });
      trackedPageView.current = true;
    }
  }, [business, service]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !service || !business) {
    return (
      <PageError
        message="There was an error loading this artist's page. Please double
          check that you've entered the url in correctly, and if the error
          persists, reach out to our support team using the help icon below."
      />
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
      }}>
      <TopBar title={business?.name} subtitle={service.serviceName} />
      <Box sx={{ flex: 1, mt: '153px', pb: '165px' }}>
        <ServiceBookingForm />
      </Box>
      <PorterFooter />
    </Box>
  );
}
