/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Box } from '@mui/material';
import { FormikForm, ServiceFormValues } from '../../../interfaces';
import ServiceStep from './ServiceStep';
import BookingProgressBar from '../../Booking/General/BookingProgressBar';
import useGetBusiness from '../../../hooks/businesses/useGetBusiness';
import {
  getBaseStorageIdentifier,
  getFormValuesStorageIdentifier,
  getInitialServiceForm,
  getStepStorageIdentifier,
  getSteps,
  isServiceLocationLocked,
  serviceFormSchema,
} from '../../../utils/services';
import {
  BOOKING_STEPS_CATEGORY,
  BookingSteps,
} from '../../../constants/services';
import StoreServiceForm from './StoreServiceForm';
import useGetServiceFromParam from '../../../hooks/services/useGetServiceFromParam';
import PageError from '../../Global/Error';

const progressCategories = ['Details', 'Schedule', 'Your Info', 'Confirm'];

function ServiceBookingForm() {
  const [step, setStep] = React.useState<number | null>(null);

  const { business } = useGetBusiness();
  const { service } = useGetServiceFromParam();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const employeeId = searchParams.get('employeeId');

  const storageIdentifier = getBaseStorageIdentifier(service);
  const stepStorageIdentifier = getStepStorageIdentifier(storageIdentifier);
  const valuesStorageIdentifier =
    getFormValuesStorageIdentifier(storageIdentifier);

  const steps = getSteps(service);
  const currentStep = typeof step === 'number' ? steps[step] : null;

  React.useEffect(() => {
    const initialStepIndex = sessionStorage.getItem(stepStorageIdentifier)
      ? Number(sessionStorage.getItem(stepStorageIdentifier))
      : 0;
    const initialStep = steps[initialStepIndex];

    const isLockedLocation =
      isServiceLocationLocked(service) &&
      initialStep === BookingSteps.PIERCING_LOCATION;

    if (isLockedLocation) {
      setStep(initialStepIndex + 1);
    } else {
      setStep(initialStepIndex);
    }
  }, [currentStep, service, step, stepStorageIdentifier, steps]);

  const isUnitialized = step === null;

  const initialFormState = getInitialServiceForm({
    storageIdentifier,
    employeeId,
    service,
  });

  const form: FormikForm<ServiceFormValues> = {
    validationSchema: serviceFormSchema,
    initialValues: initialFormState,
    onSubmit: () => {},
  };

  if (!service || !business) {
    return (
      <PageError
        message="There was an error loading this artist's page. Please double
          check that you've entered the url in correctly, and if the error
          persists, reach out to our support team using the help icon below."
      />
    );
  }

  return (
    <Box>
      {!isUnitialized && (
        <>
          <BookingProgressBar
            progressCategories={progressCategories}
            currentProgress={BOOKING_STEPS_CATEGORY[steps[step]]}
          />
          <Formik
            enableReinitialize
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            onSubmit={form.onSubmit}>
            <>
              <ServiceStep
                step={step}
                setStep={
                  setStep as React.Dispatch<React.SetStateAction<number>>
                }
              />
              <StoreServiceForm storageIdentifier={valuesStorageIdentifier} />
            </>
          </Formik>
        </>
      )}
    </Box>
  );
}

export default ServiceBookingForm;
