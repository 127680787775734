import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { Appointment } from '../../../interfaces';
import BottomActionBar from '../../Global/BottomActionBar';

interface Props {
  appointment: Appointment;
  isSubmitting: boolean;
  onSubmit: (
    confirmPayment?: () => Promise<PaymentIntentResult>,
  ) => void | Promise<void>;
  onBack?: () => void;
}

function CheckoutForm({ appointment, isSubmitting, onSubmit, onBack }: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!elements || !stripe) return;
    await onSubmit(async () => {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `https://${window.location.hostname}/booking/${appointment.appointmentId}`,
        },
        redirect: 'if_required',
      });
      return result;
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {!isSubmitting && stripe && (
        <BottomActionBar
          primaryText="Confirm & Pay"
          primaryAction={() => onSubmit()}
          primaryDisabled={isSubmitting}
          secondaryDisabled={isSubmitting}
          secondaryText={onBack ? 'Back' : undefined}
          secondaryAction={onBack ?? undefined}
          isSubmit
          centerPrimary
        />
      )}
    </form>
  );
}

export default CheckoutForm;
