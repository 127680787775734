import { addMinutes, format } from 'date-fns';
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import { Appointments } from '@prisma/client';
import Talk from 'talkjs';
import { storage } from '../firebase';
import API_URL from '../apiconfig';
import {
  User,
  Employee,
  FormValues,
  Appointment,
  Image,
  Business,
  GeneralRequest,
  Service,
  AppointmentCreateObject,
  FetchResult,
  AppointmentDepositCheckoutSession,
  ServiceFormValues,
  CreateAppointmentResult,
} from '../interfaces';
import axios, { isAxiosError } from './api';
import { ReviewLinkWithReviews } from '../types/reviews';
import {
  AppointmentGroupWithAppointments,
  GeneralRequestWithCustomers,
} from '../types/appointments';
import client from '../utils/react-query/client';
import { isNil } from '../utils/global';
import { FullService } from '../types/services';

export async function getAppointment(appointmentId: string | undefined) {
  try {
    const response = await axios.get<FetchResult<Appointments>>(
      `${API_URL}/v1/appointments/${appointmentId}`,
    );

    return response.data.result;
  } catch (error: unknown) {
    // Check if the error is an Axios error with a response and a 404 status
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 404
    ) {
      try {
        const newResponse = await axios.get<
          FetchResult<GeneralRequestWithCustomers>
        >(`${API_URL}/v1/appointments/general-requests/${appointmentId}`);

        return newResponse.data.result;
      } catch (newError) {
        // Handle errors from the second API call
        throw new Error('Cannot get appointment from either endpoint');
      }
    } else if (error instanceof Error) {
      throw error;
    } else {
      throw new Error('Unknown error occurred');
    }
  }
}

export async function getAppointmentGroup(groupId: string) {
  try {
    const response = await axios.get<
      FetchResult<AppointmentGroupWithAppointments>
    >(`${API_URL}/v1/appointments/groups/${groupId}`);

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get appointment group');
  }
}

export async function createAppointment(
  formValues: FormValues,
  employee: Employee,
  user: User,
  refImgsId: string[] | undefined,
  areaImgsId: string[] | undefined,
  appointmentCity: string,
  appointmentTimezone: string,
) {
  let { userId } = user;
  if (!userId && user.result) {
    userId = user.result.userId;
  }

  try {
    const payload: Appointment = {
      employeeId: employee.employeeId,
      customerId: userId,
      status: 'REQUESTED',
    };

    if (formValues.businessId) {
      payload.businessId = formValues.businessId;
    }

    if (formValues.budget) {
      payload.budget = formValues.budget;
    }

    if (appointmentCity !== '') {
      payload.tripCity = appointmentCity;
    }

    if (appointmentTimezone !== '') {
      payload.timezone = appointmentTimezone;
    }

    if (formValues.size) {
      payload.tattooSize = formValues.size;
    }

    if (formValues.location) {
      payload.tattooLocation = formValues.location;
    }

    if (formValues.availability) {
      payload.availability = formValues.availability;
    }

    if (formValues.firstTattoo) {
      payload.isFirstTattoo = formValues.firstTattoo === 'true';
    }

    if (formValues.newCustomer) {
      payload.isNewCustomer = formValues.newCustomer === 'true';
    }
    if (formValues.color) {
      payload.tattooColor = formValues.color;
    }
    if (formValues.details) {
      payload.tattooDetails = formValues.details;
    }
    if (formValues.inspiration) {
      payload.tattooInspiration = formValues.inspiration;
    }
    if (formValues.otherNotes) {
      payload.otherNotes = formValues.otherNotes;
    }
    if (formValues.skinTone) {
      payload.skinTone = formValues.skinTone;
    }
    if (formValues.styles) {
      payload.prefTattooStyle = formValues.styles;
    }
    if (refImgsId) {
      payload.refImgsId = refImgsId;
    }
    if (areaImgsId) {
      payload.areaImgsId = areaImgsId;
    }

    if (formValues.ongoingProject) {
      payload.ongoingProject = formValues.ongoingProject === 'true';
    }
    if (formValues.coverUp) {
      payload.coverUp = formValues.coverUp === 'true';
    }
    if (formValues.surroundingTattoos) {
      payload.surroundingTattoos = formValues.surroundingTattoos === 'true';
    }
    if (formValues.isEphemeral) {
      payload.isEphemeral = formValues.isEphemeral === 'true';
    }
    if (formValues.tattooServiceType) {
      payload.tattooServiceType = formValues.tattooServiceType;
    }

    const response = await axios.post(`${API_URL}/v1/appointments`, {
      ...payload,
      isMarketingOptIn: !!formValues.isMarketingOptIn,
    });

    return response.data.result;
  } catch (error) {
    if (
      (error as any).response.data.message ===
      'You are not authorized to create and appointment with status REQUESTED'
    ) {
      throw new Error('Cannot create appointment as employee');
    }
    throw new Error('Cannot create appointment');
  }
}

export async function createGeneralRequest(
  formValues: FormValues,
  business: Business,
  user: User,
  refImgsId: string[] | undefined,
  areaImgsId: string[] | undefined,
  selectedArtists?: string[],
) {
  let { userId } = user;
  if (!userId && user.result) {
    userId = user.result.userId;
  }

  try {
    const payload: Appointment = {
      businessId: business.businessId,
      customerId: userId,
    };

    if (formValues.budget) {
      payload.budget = formValues.budget;
    }

    if (formValues.size) {
      payload.tattooSize = formValues.size;
    }

    if (formValues.location) {
      payload.tattooLocation = formValues.location;
    }

    if (formValues.availability) {
      payload.availability = formValues.availability;
    }

    if (formValues.firstTattoo) {
      if (formValues.firstTattoo === 'true') {
        payload.isFirstTattoo = true;
      } else {
        payload.isFirstTattoo = false;
      }
    }

    if (formValues.newCustomer) {
      if (formValues.newCustomer === 'true') {
        payload.isNewCustomer = true;
      } else {
        payload.isNewCustomer = false;
      }
    }
    if (formValues.color) {
      payload.tattooColor = formValues.color;
    }
    if (formValues.details) {
      payload.tattooDetails = formValues.details;
    }
    if (formValues.inspiration) {
      payload.tattooInspiration = formValues.inspiration;
    }
    if (formValues.otherNotes) {
      payload.otherNotes = formValues.otherNotes;
    }
    if (formValues.skinTone) {
      payload.skinTone = formValues.skinTone;
    }
    if (formValues.styles) {
      payload.prefTattooStyle = formValues.styles;
    }
    if (refImgsId) {
      payload.refImgsId = refImgsId;
    }
    if (areaImgsId) {
      payload.areaImgsId = areaImgsId;
    }

    if (selectedArtists) {
      payload.selectedArtists = selectedArtists;
    }

    const response = await axios.post(
      `${API_URL}/v1/appointments/general-request`,
      {
        ...payload,
        isMarketingOptIn: !!formValues.isMarketingOptIn,
      },
    );

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot create appointment');
  }
}

export async function moveImages(images: Image[] | undefined, user: User) {
  if (!images) {
    return [];
  }

  let { authServiceId } = user;
  if (!authServiceId && user.result) {
    authServiceId = user.result.authServiceId;
  }

  const transferPromises: Promise<string>[] = [];

  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];

    const tempImageDownloadUrl = image.url;
    // eslint-disable-next-line no-await-in-loop
    const response = await fetch(tempImageDownloadUrl as URL | RequestInfo);
    // eslint-disable-next-line no-await-in-loop
    const blob = await response.blob();

    transferPromises.push(
      new Promise((resolve, reject) => {
        const timestamp = Date.now();
        const storageUrl = `users/${authServiceId}/appointment_${timestamp}_${i}`;
        const permanentImageRef = ref(storage, storageUrl);
        const uploadTask = uploadBytesResumable(permanentImageRef, blob);
        uploadTask.on(
          'state_changed',
          () => {},
          uploadError => reject(uploadError),
          async () => {
            const oldImageRef = ref(storage, `tempImages/${image.name}`);
            deleteObject(oldImageRef);

            const downloadURL = await getDownloadURL(permanentImageRef);
            resolve(downloadURL);
          },
        );
      }),
    );
  }

  try {
    const imgUrls = await Promise.all(transferPromises);
    return imgUrls;
  } catch {
    return [];
  }
}

export async function scheduleAppointment(
  startDateTime: Date,
  endDateTime: Date,
  appointmentId: string,
) {
  try {
    const appointmentResponse = await axios.patch(
      `${API_URL}/v1/appointments/${appointmentId}`,
      {
        startDateTime,
        endDateTime,
        status: 'SCHEDULED',
      },
    );

    return appointmentResponse;
  } catch (error) {
    throw new Error('Cannot schedule appointment');
  }
}

export async function reserveAppointment(
  appointmentId: string,
  dates: {
    startDateTime: Date;
    endDateTime: Date;
  }[],
) {
  const appointmentResponse = await axios.patch<
    FetchResult<{
      count: number;
    }>
  >(`${API_URL}/v1/appointments/reserve`, {
    dates,
    appointmentIdWithDeposit: appointmentId,
  });

  const currTime = new Date();
  const expTime = addMinutes(currTime, 30);
  sessionStorage.setItem('inAppointmentExpiration', JSON.stringify(expTime));

  return appointmentResponse.data.result;
}

export async function confirmAppointment(
  appointmentId: string,
  stripePaymentIntentId?: string,
  isMarketingOptIn?: boolean,
) {
  const data: Record<string, any> = {};

  if (stripePaymentIntentId) {
    data.stripePaymentIntentId = stripePaymentIntentId;
  }

  if (!isNil(isMarketingOptIn)) {
    data.isMarketingOptIn = isMarketingOptIn;
  }

  const appointmentResponse = await axios.patch(
    `${API_URL}/v1/appointments/confirm/${appointmentId}`,
    data,
  );

  return appointmentResponse;
}

export async function reschedule(
  startDateTime: Date,
  endDateTime: Date,
  appointmentId: string,
  depositPrice?: number | undefined,
) {
  const data: any = {
    startDateTime,
    endDateTime,
    status: 'RESCHEDULED',
    reschedulingFeesPaid: depositPrice || undefined,
  };

  try {
    const appointmentResponse = await axios.patch(
      `${API_URL}/v1/appointments/${appointmentId}`,
      data,
    );

    return appointmentResponse;
  } catch (error) {
    throw new Error('Cannot re-schedule appointment');
  }
}

export async function cancel(appointmentId: string) {
  try {
    const appointmentResponse = await axios.patch(
      `${API_URL}/v1/appointments/${appointmentId}`,
      {
        status: 'CANCELED',
      },
    );

    return appointmentResponse;
  } catch (error) {
    throw new Error('Cannot cancel appointment');
  }
}

export async function closeCheckout(
  appointmentId: string,
  paymentId: string,
  tattooPrice: number,
  tipAmt: number,
) {
  const appointmentResponse = await axios.patch(
    `${API_URL}/v1/appointments/complete-checkout/${appointmentId}`,
    {
      paymentId,
      tattooPrice,
      tipAmt,
    },
  );

  return appointmentResponse;
}

export async function logReviewLinkVisit(reviewLinkId: string) {
  const reviewLinkresponse = await axios.patch<
    FetchResult<ReviewLinkWithReviews>
  >(`${API_URL}/v1/appointments/review-link/${reviewLinkId}`, {
    reviewStatus: 'VISITED',
    visitedAt: new Date().toISOString(),
  });

  return reviewLinkresponse.data.result;
}

export async function postReview(
  reviewLinkId: string,
  rating: number,
  comments?: string,
) {
  const reviewLinkresponse = await axios.post(
    `${API_URL}/v1/appointments/review-link/${reviewLinkId}`,
    {
      rating,
      comments,
    },
  );

  return reviewLinkresponse.data.result;
}

export async function createAppointmentChat(
  appointment: Appointment | GeneralRequest,
) {
  let payload;

  if ((appointment as Appointment).appointmentId) {
    payload = {
      appointmentId: (appointment as Appointment).appointmentId,
    };
  } else if ((appointment as GeneralRequest).requestId) {
    payload = {
      generalRequestId: (appointment as GeneralRequest).requestId,
    };
  } else {
    throw new Error('Invalid appointment type');
  }

  try {
    const chatResponse = await axios.post(
      `${API_URL}/v1/appointments/chat`,
      payload,
    );
    return {
      conversation: chatResponse?.data?.result as Talk.ConversationData,
      appointmentId: chatResponse?.data?.meta?.appointmentId,
      generalRequestId: chatResponse?.data?.meta?.generalRequestId,
    };
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Unable to load chat: ${error.message}`);
    } else {
      // If it's not an Error instance, you might want to handle it differently
      throw new Error('An unknown error occurred');
    }
  }
}

export async function createTemporaryServiceAppointment({
  formValues,
  employee,
  user,
  service,
  previousTemporaryAppointmentId,
}: {
  formValues: ServiceFormValues;
  employee: Pick<Employee, 'employeeId'>;
  user: User;
  service: Service | FullService;
  previousTemporaryAppointmentId?: string | null;
}) {
  let { userId } = user;
  if (!userId && user.result) {
    userId = user.result.userId;
  }

  try {
    const payload: AppointmentCreateObject = {
      employeeId: employee.employeeId,
      customerId: userId,
      businessId: service.businessId,
      serviceId: service.serviceId,
      status: 'TEMPORARY',
      numberOfGuests: formValues.numberOfGuests,
    };

    if (formValues.piercingLocationId) {
      payload.piercingLocationId = formValues.piercingLocationId;
    }

    if (formValues.dob) {
      payload.dob = format(new Date(formValues.dob), 'yyyy-MM-dd');
    }

    if (previousTemporaryAppointmentId) {
      payload.previousTemporaryAppointmentId = previousTemporaryAppointmentId;
    }

    const response = await axios.post(
      `${API_URL}/v1/appointments/piercing`,
      payload,
    );

    return response.data.result as CreateAppointmentResult;
  } catch (error) {
    throw new Error('Cannot create appointment');
  }
}

export async function getAppointmentDepositCheckoutSession({
  appointmentId,
  status,
  isPriceOnly,
}: {
  appointmentId: string;
  status: 'schedule' | 'reschedule';
  isPriceOnly?: boolean;
}) {
  try {
    const params = {
      appointmentId,
      status,
      isPriceOnly,
    };
    const response = await axios.get(`${API_URL}/v1/payment_intent/deposit`, {
      params,
    });

    return response.data.result as AppointmentDepositCheckoutSession;
  } catch (error) {
    throw new Error('Cannot get deposit checkout session');
  }
}

export async function invalidateAppointment() {
  await client.invalidateQueries({
    queryKey: ['appointment'],
    exact: false,
  });
}

export async function invalidateAppointmentGroup() {
  await client.invalidateQueries({
    queryKey: ['appointment-group'],
    exact: false,
  });
}
