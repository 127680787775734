/* eslint-disable react/require-default-props */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { Employee } from '../../interfaces';
import FormHeader from './FormHeader';

type CityOptions = {
  name: string;
  timezone: string | undefined;
  state: string;
  businessId?: string;
  isPrimaryBusiness?: boolean;
  dateRanges?: Array<{
    startDateTime: string;
    endDateTime: string;
  }>;
};

// Move data transformation logic outside component
const getArtistName = (employee: Employee): string =>
  employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

const getCityOptions = (employee: Employee): CityOptions[] => {
  const options: CityOptions[] = [];

  // Add primary business location
  options.push({
    name: employee.business?.city || '',
    timezone: employee.user.timezone,
    state: employee.business?.state || '',
    businessId: employee.business?.businessId,
    isPrimaryBusiness: true,
  });

  // Group trips by city
  const tripsByCity = employee.trips!.reduce(
    (acc, trip) => {
      if (trip.booksOpen) {
        if (!acc[trip.city]) {
          acc[trip.city] = {
            name: trip.city,
            timezone: trip.timezone,
            state: trip.state,
            dateRanges: [],
          };
        }
        acc[trip.city].dateRanges!.push({
          startDateTime: trip.startDateTime,
          endDateTime: trip.endDateTime,
        });
      }
      return acc;
    },
    {} as Record<string, CityOptions>,
  );

  // Sort date ranges for each city and get earliest date
  const citiesWithDates = Object.values(tripsByCity).map(cityOption => {
    cityOption.dateRanges!.sort(
      (a, b) =>
        new Date(a.startDateTime).getTime() -
        new Date(b.startDateTime).getTime(),
    );
    return {
      ...cityOption,
      earliestDate: new Date(cityOption.dateRanges![0].startDateTime).getTime(),
    };
  });

  // Sort cities by earliest date and add to options
  citiesWithDates
    .sort((a, b) => a.earliestDate - b.earliestDate)
    .forEach(cityOption => {
      const { earliestDate, ...cityData } = cityOption;
      options.push(cityData);
    });

  return options;
};

type Props = {
  employee: Employee | undefined;
  appointmentCity: string;
  setAppointmentCity?: React.Dispatch<React.SetStateAction<string>>;
  setAppointmentTimezone?: React.Dispatch<React.SetStateAction<string>>;
  setNameStep: React.Dispatch<React.SetStateAction<number>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: (field: string, value: any) => void;
};

const validationSchema = yup.object({
  city: yup.string().required('Please select a city from the dropdown'),
});

function AppointmentCity({
  employee,
  appointmentCity,
  setAppointmentCity,
  setAppointmentTimezone,
  setNameStep,
  setSubmit,
  setFieldValue,
}: Props) {
  const theme = useTheme();

  // Memoize expensive computations
  const artistName = React.useMemo(
    () => (employee ? getArtistName(employee) : ''),
    [employee],
  );

  const cityOptions = React.useMemo(
    () => (employee ? getCityOptions(employee) : []),
    [employee],
  );

  const formik = useFormik({
    initialValues: {
      city: appointmentCity,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setSubmit(true);
      const cityOption = cityOptions.find(item => item.name === values.city);

      if (setAppointmentCity) {
        setAppointmentCity(values.city);
      }

      if (cityOption?.timezone) {
        sessionStorage.setItem('timezone', cityOption.timezone);
        if (setAppointmentTimezone) {
          setAppointmentTimezone(cityOption.timezone);
        }
      }

      setFieldValue('businessId', cityOption?.businessId ?? '');
      sessionStorage.setItem('businessId', cityOption?.businessId ?? '');
      sessionStorage.setItem('city', values.city);
    },
  });

  if (!employee) {
    return <Typography variant="inherit">Loading...</Typography>;
  }

  return (
    <form onSubmit={formik.handleSubmit} id="appointment-city">
      <FormHeader
        header={`Which city do you want to schedule ${artistName} in?`}
      />

      <Box className="input-container">
        <TextField
          id="city"
          select
          name="city"
          label="City"
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}>
          {cityOptions.map(cityOption => (
            <MenuItem
              key={cityOption.name}
              value={cityOption.name}
              className="appointment-city-choice"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}>
              {cityOption.name}, {cityOption.state}
              <Typography
                variant="body2"
                sx={{ color: theme.palette.medGrey.main }}>
                {cityOption.isPrimaryBusiness
                  ? 'Primary Business'
                  : cityOption
                      .dateRanges!.map(
                        range =>
                          `${new Date(
                            range.startDateTime,
                          ).toLocaleDateString()} - ${new Date(
                            range.endDateTime,
                          ).toLocaleDateString()}`,
                      )
                      .join(', ')}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryAction={() => setNameStep(1)}
        secondaryText="Back"
      />
    </form>
  );
}

export default AppointmentCity;
