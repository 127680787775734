import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Talk from 'talkjs';
import useGetAppointment from '../appointments/useGetAppointment';
import useGetConversationAppointment from './useGetConversationAppointment';
import { getAppointmentIdFromConversation } from '../../utils/chats';

function useGetChatHeaderAppointment(
  conversation: Talk.ConversationData | null,
) {
  const [searchParams] = useSearchParams();

  const appointmentIdFromParam =
    searchParams.get('appointment') || searchParams.get('request');

  const appointmentId = conversation
    ? getAppointmentIdFromConversation(conversation)
    : null;
  const {
    data,
    isPlaceholderData,
    isLoading: isLoadingAppointment,
    isEnabled: isEnabledAppointment,
  } = useGetAppointment(appointmentId);
  const {
    data: conversationAppointmentData,
    isPlaceholderData: isConversationAppointmentPlaceholderData,
    isLoading: isLoadingConversationAppointment,
    isEnabled: isEnabledConversationAppointment,
  } = useGetConversationAppointment(conversation?.id, appointmentIdFromParam, {
    enabled: !isEnabledAppointment,
  });

  const isEnabled = isEnabledAppointment || isEnabledConversationAppointment;
  const isLoading =
    isEnabled && (isLoadingAppointment || isLoadingConversationAppointment);

  const appointment = React.useMemo(() => {
    if ((!appointmentId && !appointmentIdFromParam) || !conversation)
      return null;
    return appointmentId
      ? isPlaceholderData
        ? null
        : { appointment: data, appointmentId }
      : isConversationAppointmentPlaceholderData
      ? null
      : {
          appointment: conversationAppointmentData,
          appointmentId: appointmentIdFromParam,
        };
  }, [
    conversation,
    appointmentId,
    appointmentIdFromParam,
    conversationAppointmentData,
    data,
    isConversationAppointmentPlaceholderData,
    isPlaceholderData,
  ]);

  return { ...appointment, isLoading, isEnabled, appointmentIdFromParam };
}

export default useGetChatHeaderAppointment;
