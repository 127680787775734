import { useQuery } from '@tanstack/react-query';
import { addMinutes, format, isSameMinute, subMinutes } from 'date-fns';
import { Appointments, ServiceCategory } from '@prisma/client';
import { getAvailableTimes } from '../../services/availability';
import {
  Appointment,
  AvailableTimes,
  Employee,
  Service,
} from '../../interfaces';
import {
  getEmployeeMinutesDurationByDay,
  timeIsInFirstHalfOfDay,
} from '../../utils/employee';
import { TypeOnSchedResponse } from '../../types/onsched';
import { TakenSessionSlot } from '../../types/appointments';
import { FullService } from '../../types/services';

function useDayAvailableTimes({
  day,
  selectedDay,
  employee,
  timezone,
  duration,
  service,
  appointment,
  onschedResource,
  takenSlots,
}: {
  day: Date | null;
  selectedDay: Date;
  employee: Employee;
  timezone: string;
  duration: number;
  service?: Service | FullService;
  appointment: Appointment | Appointments | null;
  onschedResource: TypeOnSchedResponse.Resource | null | undefined;
  takenSlots?: TakenSessionSlot[];
}) {
  const { startTime, endTime, openDurationInMinutes } =
    getEmployeeMinutesDurationByDay(onschedResource?.availability, selectedDay);

  const formattedDay = format(selectedDay, 'yyyy-MM-dd');

  const getDayAvailableTimes = async () => {
    const tempAvailableTimes = await getAvailableTimes({
      startDate: formattedDay,
      endDate: formattedDay,
      duration,
      employeeId: employee.employeeId,
      timezone,
      onschedServiceId: service?.onschedServiceId,
      businessId: appointment?.businessId || undefined,
      serviceCategory: appointment?.serviceCategory as ServiceCategory,
      takenSlots,
    });

    const times = tempAvailableTimes.availableTimes;
    if (employee.stackTimes) {
      const filteredAvailableTimes: AvailableTimes[] = [];

      for (let i = 0; i < times.length; i += 1) {
        const currentTime = times[i];
        const prevTime = times[i - 1];
        const nextTime = times[i + 1];

        const currentStartTime = new Date(currentTime.startDateTime);

        if (!nextTime || !prevTime) {
          filteredAvailableTimes.push(currentTime);
        }

        if (prevTime && nextTime) {
          const prevStartTime = new Date(prevTime.startDateTime);
          const nextStartTime = new Date(nextTime.startDateTime);

          if (!isSameMinute(currentStartTime, addMinutes(prevStartTime, 30))) {
            filteredAvailableTimes.push(currentTime);
          } else if (
            !isSameMinute(currentStartTime, subMinutes(nextStartTime, 30))
          ) {
            filteredAvailableTimes.push(currentTime);
          }
        }
      }

      const finalFilteredTimes = filteredAvailableTimes.filter(
        availableTime =>
          new Date(availableTime.startDateTime) >=
          addMinutes(new Date(), employee.bookInAdvanceMinutes),
      );

      if (!openDurationInMinutes) return finalFilteredTimes;

      const isAppointmentMoreThanHalfDay = duration > openDurationInMinutes / 2;
      const isLessThanHalfDay = duration < openDurationInMinutes / 2;
      const placement = employee.longerAppointmentsPlacement;

      const isLongAppointmentWithBeginning =
        isAppointmentMoreThanHalfDay && placement === 'BEGINNING';
      const isShortAppointmentWithEnd =
        isLessThanHalfDay && placement === 'END';

      const isLongAppointmentWithEnd =
        isAppointmentMoreThanHalfDay && placement === 'END';
      const isShortAppointmentWithBeginning =
        isLessThanHalfDay && placement === 'BEGINNING';

      // Show only first time if longer appointment with beginning placement, or shorter appointment with ending placement
      const showFirstTime =
        isLongAppointmentWithBeginning || isShortAppointmentWithEnd;

      // Show only last time if longer appointment with ending placement, or shorter appointment with beginning placement
      const showLastTime =
        isLongAppointmentWithEnd || isShortAppointmentWithBeginning;

      if (showFirstTime) {
        const timeToShow = finalFilteredTimes.length
          ? [finalFilteredTimes[0]]
          : [];
        return timeToShow;
      }

      if (showLastTime) {
        const timeToShow = finalFilteredTimes.length
          ? [finalFilteredTimes[finalFilteredTimes.length - 1]]
          : [];

        const isTimeInMorning = timeIsInFirstHalfOfDay({
          timeToCheck: timeToShow[0].time,
          openTime: startTime,
          closeTime: endTime,
        });

        const showTime = isLongAppointmentWithEnd || !isTimeInMorning;

        return showTime ? timeToShow : [];
      }

      // Otherwise (including half-day or placement === 'ANY'), return all
      return finalFilteredTimes;
    }
    return times;
  };

  const { data, isPlaceholderData, isLoading, isFetching, isError, error } =
    useQuery({
      queryKey: [
        'dayAvailableTimes',
        formattedDay,
        employee.employeeId,
        timezone,
        duration,
        service?.onschedServiceId,
        appointment?.businessId,
        appointment?.serviceCategory,
        onschedResource,
        takenSlots,
      ],
      queryFn: getDayAvailableTimes,
      enabled: !!day && !!employee,
      staleTime: 1000 * 30, // 30 seconds
      gcTime: 1000 * 60, // 60 seconds
    });

  return {
    data: isPlaceholderData ? null : data,
    isPlaceholderData,
    isLoading,
    isFetching,
    isError,
    error,
  };
}

export default useDayAvailableTimes;
