import Talk from 'talkjs';
import { isCuid } from './global';
import type { createAppointmentChat } from '../services/appointment';

export function getAppointmentIdFromConversation(
  conversation: Talk.ConversationData,
) {
  return (
    conversation.custom?.appointment_id ||
    conversation.custom?.request_id ||
    (isCuid(conversation.id) ? conversation.id : null)
  );
}

export function navigateToChat(
  chatResponse: Awaited<ReturnType<typeof createAppointmentChat>>,
) {
  const { conversation, appointmentId, generalRequestId } = chatResponse;
  const searchParams = new URLSearchParams();
  if (appointmentId && conversation.id !== appointmentId)
    searchParams.set('appointment', appointmentId);
  if (generalRequestId && conversation.id !== generalRequestId)
    searchParams.set('request', generalRequestId);
  const query = searchParams.toString();
  window.location.href = `/chat/${conversation.id}${query ? `?${query}` : ''}`;
}
