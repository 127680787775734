import { WaiverRequests, WaiverRequestSessions } from '@prisma/client';
import API_URL from '../apiconfig';
import { Appointment } from '../interfaces';
import {
  FullWaiver,
  SubmitWaiverPayload,
  TrackWaiverViewPayload,
  TrackWaiverViewResponse,
  UpdateWaiverResponse,
} from '../types/waivers';
import axios from './api';

export async function getAppointmentWaiver(
  waiverId: string,
  isPreview = false,
) {
  try {
    const response = await axios.get(`${API_URL}/v1/waivers/customer`, {
      params: {
        waiverId,
        isPreview,
      },
    });
    return response.data.result?.published as FullWaiver;
  } catch (error) {
    throw new Error('Cannot get waiver');
  }
}

export async function submitWaiver(payload: SubmitWaiverPayload) {
  try {
    const response = await axios.patch(`${API_URL}/v1/waivers/submit`, payload);

    return response.data.result as UpdateWaiverResponse;
  } catch (error) {
    throw new Error('Cannot submit waiver');
  }
}

export async function trackWaiverView(payload: TrackWaiverViewPayload) {
  try {
    const response = await axios.patch(`${API_URL}/v1/waivers/view`, payload);
    return response.data.result as TrackWaiverViewResponse;
  } catch (error) {
    throw new Error('Cannot track waiver view');
  }
}

export async function getWaiverFromRequestSession(sessionId: string) {
  const response = await axios.get(
    `${API_URL}/v1/waivers/session/${sessionId}/waiver`,
  );

  return response.data.result as {
    session: WaiverRequestSessions;
    waiver: FullWaiver;
    appointment: Appointment;
    waiverRequest: WaiverRequests;
  };
}
