import { useParams } from 'react-router-dom';
import useGetService from './useGetService';

function useGetServiceFromParam() {
  const { serviceId } = useParams();
  const numericServiceId = Number(serviceId);

  const {
    data: service,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetService(numericServiceId);

  return {
    serviceId: numericServiceId,
    service,
    isLoading,
    isError,
    isFetching,
    refetch,
  };
}

export default useGetServiceFromParam;
