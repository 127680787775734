/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PaidIcon from '@mui/icons-material/Paid';
import MessageIcon from '@mui/icons-material/Message';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Appointments } from '@prisma/client';
import { Appointment, Business, Employee, Service } from '../../../interfaces';
import {
  getScheduledAppointmentsListForConfirm,
  getFullPriceText,
  getLengthText,
  getScheduleAcceptedMessage,
  getAcceptedAppointmentsListForConfirm,
  getConfirmedAppointmentsInGroup,
  isAppointmentCompleted,
  getAppointmentStatus,
} from '../../../utils/appointment';
import { createAppointmentChat } from '../../../services/appointment';
import {
  AppointmentGroupWithAppointments,
  DateAction,
} from '../../../types/appointments';
import AppointmentDateLineItem from './AppointmentDateLineItem';
import { navigateToChat } from '../../../utils/chats';
import { FullService } from '../../../types/services';

type Props = {
  appointment: Appointment;
  appointmentGroup?: AppointmentGroupWithAppointments;
  service?: Service | FullService;
  duration?: number;
  employee: Employee | undefined;
  business?: Business | undefined;
  aptDate?: Date;
  appointmentsToDates?: Record<string, Date | null>;
  location?: boolean;
  serviceType?: boolean;
  length?: boolean;
  price?: boolean;
  designMessage?: boolean;
  appointmentDate?: boolean;
  cancellation?: boolean;
  changeDateAction?: DateAction;
  changeDateText?: string;
  removeDateAction?: DateAction;
  isReschedule?: boolean;
  isScheduleAdditional?: boolean;
  isScheduleGroupRest?: boolean;
  showConfirmedAppointments?: boolean;
  additionalAppointmentsMessage?: boolean;
};

const getAppointmentTimes = (
  appointments: (
    | Pick<
        Appointment | Appointments,
        | 'appointmentId'
        | 'startDateTime'
        | 'appointmentLengthInMinutes'
        | 'timezone'
        | 'status'
      >
    | undefined
  )[],
) =>
  [...appointments]
    .filter(apt => !!apt?.startDateTime && !!apt?.appointmentLengthInMinutes)
    .sort(
      (a, b) =>
        new Date(a!.startDateTime!).getTime() -
        new Date(b!.startDateTime!).getTime(),
    )
    .map(apt => ({
      appointmentId: apt!.appointmentId,
      startDateTime: apt!.startDateTime!,
      duration: apt!.appointmentLengthInMinutes!,
      timezone: apt!.timezone || null,
    }));

export default function AppointmentDetailsList({
  appointment,
  appointmentGroup,
  service,
  duration,
  employee,
  business,
  aptDate,
  appointmentsToDates,
  location,
  serviceType,
  length,
  price,
  designMessage,
  appointmentDate,
  cancellation,
  changeDateAction,
  changeDateText,
  removeDateAction,
  isReschedule,
  isScheduleAdditional,
  showConfirmedAppointments,
  isScheduleGroupRest,
  additionalAppointmentsMessage,
}: Props) {
  const theme = useTheme();

  const aptStartDateTime =
    appointmentsToDates?.[appointment?.appointmentId!] ||
    aptDate ||
    appointment?.startDateTime;

  const currentAppointmentStatus = getAppointmentStatus(appointment);
  const isAllRequired = appointmentGroup?.schedulingType === 'ALL';

  const scheduledAppointmentsList = getScheduledAppointmentsListForConfirm(
    appointment
      ? {
          ...appointment,
          startDateTime: aptStartDateTime
            ? new Date(aptStartDateTime).toISOString()
            : undefined,
        }
      : undefined,
    appointmentGroup?.appointments ?? [],
  );

  const acceptedAppointmentsList = isScheduleAdditional
    ? getAcceptedAppointmentsListForConfirm(
        appointment,
        appointmentGroup,
        appointmentsToDates ?? {},
      )
    : [];

  const unscheduledAppointmentsInGroup =
    currentAppointmentStatus === 'CONFIRMED'
      ? (appointmentGroup?.appointments ?? []).filter(
          apt => getAppointmentStatus(apt) === 'ACCEPTED',
        )
      : [];
  const firstUnscheduledAppointmentInGroup = unscheduledAppointmentsInGroup[0];

  const acceptedAppointmentsForPrice =
    currentAppointmentStatus === 'ACCEPTED'
      ? isScheduleAdditional
        ? acceptedAppointmentsList
        : (appointmentGroup?.appointments ?? []).filter(
            appt => getAppointmentStatus(appt) === 'ACCEPTED',
          )
      : [];

  const confirmedAppointmentsInGroup =
    currentAppointmentStatus === 'ACCEPTED' ||
    currentAppointmentStatus === 'CONFIRMED' ||
    currentAppointmentStatus === 'SCHEDULED'
      ? getConfirmedAppointmentsInGroup(
          appointmentGroup,
          currentAppointmentStatus === 'CONFIRMED'
            ? [appointment!.appointmentId!]
            : [],
        )
      : [];

  const scheduleAcceptedMessage = getScheduleAcceptedMessage(appointmentGroup);

  const times = getAppointmentTimes(scheduledAppointmentsList);

  const { studioName, studioAddress, cityStateZip } = React.useMemo(() => {
    const studioDetails = {
      studioName: '',
      studioAddress: '',
      cityStateZip: '',
    };

    if (appointment && employee) {
      const appointmentsTrip = employee.trips?.find(
        trip => trip.city === appointment.tripCity,
      );
      if (appointment.tripCity && appointmentsTrip) {
        if (appointmentsTrip.studioName) {
          studioDetails.studioName = appointmentsTrip.studioName;
        }

        if (appointmentsTrip.streetAddress) {
          let tempStreetAddress = appointmentsTrip.streetAddress;
          if (appointmentsTrip.streetAddress2) {
            tempStreetAddress += `, ${appointmentsTrip.streetAddress2}`;
          }
          studioDetails.studioAddress = tempStreetAddress;
        }

        studioDetails.cityStateZip = `${appointmentsTrip.city}, ${appointmentsTrip.state} ${appointmentsTrip.zip}`;
      } else if (business) {
        studioDetails.studioName = business.name;

        if (business.streetAddress) {
          let tempStreetAddress = business.streetAddress;
          if (business.streetAddress2) {
            tempStreetAddress += `, ${business.streetAddress2}`;
          }
          studioDetails.studioAddress = tempStreetAddress;
        }

        studioDetails.cityStateZip = `${business.city}, ${business.state} ${business.zip}`;
      }
    } else if (employee && !appointment && service) {
      if (employee.business) {
        studioDetails.studioName = employee.business.name;

        if (employee.business.streetAddress) {
          let tempStreetAddress = employee.business.streetAddress;
          if (employee.business.streetAddress2) {
            tempStreetAddress += `, ${employee.business.streetAddress2}`;
          }
          studioDetails.studioAddress = tempStreetAddress;
        }

        studioDetails.cityStateZip = `${employee.business.city}, ${employee.business.state} ${employee.business.zip}`;
      }
    }

    return studioDetails;
  }, [appointment, employee, service, business]);

  const openChat = React.useCallback(async () => {
    if (appointment) {
      const chatObject = await createAppointmentChat(appointment);
      navigateToChat(chatObject);
    }
  }, [appointment]);

  const depositPrice = isReschedule
    ? appointment?.depositPrice || appointment?.reschedulePrice
    : appointment?.depositPrice;

  return (
    <Box
      style={{
        padding: '0px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}>
      {location && (
        <Box className="schedule-detail-item">
          <LocationOnIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {studioName && (
              <Typography variant="body2">{studioName}</Typography>
            )}
            {studioAddress && (
              <Typography variant="body2">{studioAddress}</Typography>
            )}
            {cityStateZip && (
              <Typography variant="body2">{cityStateZip}</Typography>
            )}
          </Box>
        </Box>
      )}
      {length && (
        <Box className="schedule-detail-item">
          <WatchLaterIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {!!appointment?.appointmentLengthInMinutes && (
              <Typography variant="body2">
                {getLengthText(appointment.appointmentLengthInMinutes)}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {serviceType && aptDate && (
        <AppointmentDateLineItem
          date={aptDate}
          duration={duration || 0}
          timezone={employee?.user.timezone || 'US/Pacific'}
        />
      )}

      {(isScheduleAdditional || showConfirmedAppointments) &&
        confirmedAppointmentsInGroup.map(apt => (
          <AppointmentDateLineItem
            key={apt.appointmentId}
            date={apt.startDateTime ? new Date(apt.startDateTime) : null}
            duration={apt.appointmentLengthInMinutes || 0}
            timezone={apt.timezone || employee?.user.timezone || 'US/Pacific'}
            isConfirmed={currentAppointmentStatus !== 'CONFIRMED'}
            isComplete={isAppointmentCompleted(apt)}
          />
        ))}

      {!isScheduleAdditional &&
        appointmentDate &&
        times?.map((time, index) => (
          <AppointmentDateLineItem
            key={String(time.startDateTime)}
            date={new Date(time.startDateTime!)}
            duration={time.duration || 0}
            timezone={time.timezone || employee?.user.timezone || 'US/Pacific'}
            totalCount={times.length}
            index={index}
            isCurrent={
              // Show badge if is current appointment and there are other confirmed appointments in group
              time.appointmentId === appointment?.appointmentId &&
              showConfirmedAppointments &&
              !!confirmedAppointmentsInGroup?.length
            }
            changeDate={
              time.appointmentId === appointment?.appointmentId &&
              changeDateAction &&
              changeDateText
                ? {
                    onClick: () =>
                      changeDateAction({
                        appointmentId: time.appointmentId!,
                      }),
                    text: changeDateText,
                  }
                : undefined
            }
          />
        ))}

      {isScheduleAdditional &&
        acceptedAppointmentsList.map((apt, index) => (
          <AppointmentDateLineItem
            key={apt.appointmentId}
            date={apt.startDateTime ? new Date(apt.startDateTime) : null}
            duration={apt.appointmentLengthInMinutes || 0}
            timezone={apt.timezone || employee?.user.timezone || 'US/Pacific'}
            changeDate={{
              text: apt.startDateTime
                ? changeDateText || 'Choose a different date'
                : apt.isOptional
                ? 'Add follow-up appointment'
                : 'Choose Next Date',
              onClick: () => {
                changeDateAction?.({
                  appointmentId: apt.appointmentId!,
                });
              },
            }}
            removeDate={
              apt.startDateTime && index > 0 && !isAllRequired
                ? {
                    text: 'Remove',
                    onClick: () => {
                      removeDateAction?.({
                        appointmentId: apt.appointmentId!,
                      });
                    },
                  }
                : undefined
            }
          />
        ))}

      {!!firstUnscheduledAppointmentInGroup && isScheduleGroupRest && (
        <AppointmentDateLineItem
          key={firstUnscheduledAppointmentInGroup.appointmentId}
          date={
            firstUnscheduledAppointmentInGroup.startDateTime
              ? new Date(firstUnscheduledAppointmentInGroup.startDateTime)
              : null
          }
          duration={
            firstUnscheduledAppointmentInGroup.appointmentLengthInMinutes || 0
          }
          timezone={
            firstUnscheduledAppointmentInGroup.timezone ||
            employee?.user.timezone ||
            'US/Pacific'
          }
          changeDate={{
            text: 'Schedule follow-up appointment',
            onClick: () => {
              window.location.href = `/booking/${firstUnscheduledAppointmentInGroup.appointmentId}`;
            },
          }}
        />
      )}

      {additionalAppointmentsMessage && scheduleAcceptedMessage && (
        <Box className="schedule-detail-item">
          <DateRangeIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            <Typography variant="body2">{scheduleAcceptedMessage}</Typography>
          </Box>
        </Box>
      )}

      {price && (
        <Box className="schedule-detail-item">
          <PaidIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {appointment && appointment.priceType && (
              <Typography variant="body2">
                {getFullPriceText({
                  appointment: {
                    ...appointment,
                    startDateTime:
                      appointmentsToDates?.[appointment.appointmentId!] ||
                      appointment.startDateTime,
                    depositPrice,
                  },
                  appointmentGroup,
                  additionalAppointments: [
                    ...scheduledAppointmentsList,
                    ...acceptedAppointmentsForPrice,
                  ]
                    .filter(
                      apt => apt.appointmentId !== appointment.appointmentId,
                    )
                    .map(appt => ({
                      ...appt,
                      startDateTime:
                        appointmentsToDates?.[appt.appointmentId!] ||
                        appt.startDateTime,
                    })),
                  onlyAppointmentsWithDates: isScheduleAdditional,
                })}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {serviceType && (
        <Box className="schedule-detail-item">
          <TextSnippetIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {service && (
              <Typography variant="body2">
                {service.serviceName} with {employee?.displayName}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {designMessage && (
        <Box className="schedule-detail-item">
          <MessageIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {(appointment?.customDesignMessage ||
              employee?.standardDesignMessage) && (
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {appointment?.customDesignMessage ||
                  employee?.standardDesignMessage}
              </Typography>
            )}
            <Button
              variant="text"
              style={{ fontSize: 14 }}
              className="chat-text-btn"
              id="open-chat-request"
              onClick={openChat}>
              Chat with{' '}
              {employee?.displayName ||
                `${employee?.user.firstName}` ||
                'your artist'}
            </Button>
          </Box>
        </Box>
      )}

      {cancellation && (
        <Box className="schedule-detail-item">
          <CancelIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            <Typography variant="body2">
              {employee?.cancellationPolicyText}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
