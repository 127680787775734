import Talk from 'talkjs';
import API_URL from '../apiconfig';
import { Appointment, FetchResult } from '../interfaces';
import axios from './api';

export async function getConversationAppointment(
  conversationId: string,
  appointmentId: string,
) {
  try {
    const response = await axios.get<FetchResult<Appointment>>(
      `${API_URL}/v1/talkjs/conversations/${conversationId}/appointments/${appointmentId}`,
    );

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get available days');
  }
}

export async function getConversation(conversationId: string) {
  try {
    const response = await axios.get<FetchResult<Talk.ConversationData>>(
      `${API_URL}/v1/talkjs/conversations/${conversationId}`,
    );

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get available days');
  }
}
