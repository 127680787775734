import { Box, CircularProgress, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import '../../Booking/Booking.css';
import {
  Business,
  Employee,
  Service,
  ServiceFormValues,
} from '../../../interfaces';
import PageLayout from '../../Global/PageLayout';
import { BookingSteps } from '../../../constants/services';
import useCreateTemporaryServiceAppointment from '../../../hooks/appointments/useCreateTemporaryServiceAppointment';
import useUser from '../../../hooks/global/useUser';
import Login from '../../auth/Login';
import Confirm from '../../Booking/Confirm/Confirm';
import BottomActionBar from '../../Global/BottomActionBar';
import { FullService } from '../../../types/services';

type Props = {
  service: Service | FullService;
  employee: Employee;
  business: Business;
  selectedDateTime: Date;
  prevStep: () => void;
  backToStep: (stepName: BookingSteps) => void;
};

function ConfirmPiercing({
  service,
  employee,
  business,
  selectedDateTime,
  prevStep,
  backToStep,
}: Props) {
  const { user, isLoading: isUserLoading } = useUser();
  const { values } = useFormikContext<ServiceFormValues>();

  const {
    data: appointment,
    error,
    isLoading,
  } = useCreateTemporaryServiceAppointment({
    service,
    values,
    employee,
  });

  const calculateAgeLimit = () => {
    if (!service?.minimumAgeInMonths) return null;
    const minAge =
      service.minimumAgeInMonths % 12 === 0
        ? service.minimumAgeInMonths / 12
        : service.minimumAgeInMonths;
    const minAgeUnit =
      service.minimumAgeInMonths % 12 === 0 ? 'years' : 'months';
    return { minAge, minAgeUnit };
  };

  const renderErrorMessage = () => {
    if (!error) return null;

    window.scroll(0, 0);

    const ageLimit = calculateAgeLimit();
    if (error.message === 'MIN_AGE_NOT_MET' && ageLimit) {
      if (business?.businessId === 'cm01dmawi009uyhu4jbmdiqo2') {
        return `You must be ${ageLimit.minAge} ${ageLimit.minAgeUnit} or older to book this service online. This service is available to minors but must be booked directly through the shop. Please contact the shop at (516) 794-5844 for more information.`;
      }
      return `You must be ${ageLimit.minAge} ${ageLimit.minAgeUnit} or older to book this service online. Please contact the shop for more information.`;
    }

    return (
      error.message ||
      'An unexpected error occurred. Please refresh the page and try again.'
    );
  };

  return (
    <PageLayout
      showBottomActionBar={false}
      childrenContainerSx={{
        padding: 0,
        width: '100%',
      }}>
      {!!user && (
        <>
          {!!error && (
            <>
              <Typography variant="body1" className="error">
                {renderErrorMessage()}
              </Typography>
              <BottomActionBar
                secondaryAction={prevStep}
                secondaryText="Back"
              />
            </>
          )}

          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={2}>
              <CircularProgress />
            </Box>
          )}

          {!!appointment?.appointmentId && (
            <Confirm
              service={service}
              appointment={appointment}
              employee={employee}
              business={business}
              appointmentsToDates={{
                [appointment.appointmentId]: selectedDateTime,
              }}
              status="schedule"
              isBack
              backAction={prevStep}
              isMarketingOptIn={values.isMarketingOptIn}
              changeDateAction={() => backToStep(BookingSteps.CALENDAR)}
            />
          )}
        </>
      )}
      {!user && !isUserLoading && <Login />}
    </PageLayout>
  );
}

export default ConfirmPiercing;
