/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import Talk from 'talkjs';
import { Inbox, Session as TalkJsSession } from '@talkjs/react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Roles } from '@prisma/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import Login from '../components/auth/Login';
import TALK_APP_ID from '../talkconfig';
import { isUserEmployeeOrAdmin } from '../utils/employee';
import useUser from '../hooks/global/useUser';
import ChatBoxHeader from '../components/chats/ChatBoxHeader';
import '../App.css';
import './chat.css';
import useParamConversation from '../hooks/chat/useParamConversation';

export default function ChatPage() {
  const { user, isLoading: authLoading } = useUser();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [selectedConversation, setSelectedConversation] =
    React.useState<Talk.ConversationData | null>(null);

  const { isLoading } = useParamConversation({
    selectedConversation,
    setSelectedConversation,
  });

  const syncUser = React.useCallback(() => {
    const talkJsId =
      user && isUserEmployeeOrAdmin(user?.role as Roles)
        ? `${user.userId}-customer`
        : user?.userId;
    const userName = `${user?.firstName || ''} ${user?.lastName || ''}`;
    return new Talk.User({
      id: talkJsId!,
      name: userName,
      email: user!.email,
      phone: user!.phoneNumber,
      role: 'customer',
    });
  }, [user]);

  const { Session, props: sessionProps } = React.useMemo<
    | {
        Session: typeof TalkJsSession;
        props: React.ComponentProps<typeof TalkJsSession>;
      }
    | {
        Session: React.FC;
        props: {};
      }
  >(
    () =>
      user?.userId
        ? {
            Session: TalkJsSession,
            props: {
              appId: TALK_APP_ID,
              syncUser,
            },
          }
        : { Session: React.Fragment, props: {} },
    [user?.userId, syncUser],
  );

  const onConversationSelected = React.useCallback(
    (conversation: Talk.ConversationData | null) => {
      setSelectedConversation(conversation);
    },
    [],
  );

  const resetParams = React.useCallback(() => {
    if (searchParams.get('appointment') || searchParams.get('request')) {
      navigate('/chat', {
        replace: true,
        preventScrollReset: true,
      });
    }
  }, [navigate, searchParams]);

  switch (true) {
    // Everything is loading
    case authLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !authLoading && !user:
      return (
        <Container maxWidth="lg" className="login">
          <Login />
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="chat-container">
          {/* @ts-ignore */}
          <Session {...sessionProps}>
            {!selectedConversation && (
              <div className="chat-header">
                <Typography variant="h1">Inbox</Typography>
              </div>
            )}
            <div
              id="inbox-container"
              style={{
                display: 'flex',
                height: '100dvh',
                flexDirection: 'column',
              }}>
              <ChatBoxHeader
                selectedConversation={selectedConversation}
                onSelectConversation={onConversationSelected}
              />
              {!isLoading && (
                <Inbox
                  className="chat-div"
                  style={{
                    flex: '1 1 auto',
                    maxHeight: 'max(100dvh - 100px, 700px)',
                  }}
                  conversationId={selectedConversation?.id || null}
                  onSelectConversation={resetParams}
                  onConversationSelected={event => {
                    onConversationSelected(event.conversation);
                  }}
                  messageField={{ autofocus: false }}
                  showChatHeader={false}
                  showFeedHeader={false}
                  showMobileBackButton={false}
                  loadingComponent={<CircularProgress sx={{ mt: 2 }} />}
                />
              )}
              {isLoading && (
                <Stack width="100%" justifyContent="center" alignItems="center">
                  <CircularProgress sx={{ mt: 2 }} />
                </Stack>
              )}
            </div>
          </Session>
        </Container>
      );
  }
}
