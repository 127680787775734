import * as React from 'react';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import BottomActionBar from './BottomActionBar';
import FormHeader, { FormHeaderAlert } from '../Form/FormHeader';
import PorterFooter from './PorterFooter';
import { isNil, sxToObject } from '../../utils/global';

type Props = {
  children: React.ReactNode;
  header?: string;
  subheader?: string;
  onNext?: () => void;
  onBack?: () => void;
  nextDisabled?: boolean;
  isNextSubmit?: boolean;
  primaryText?: string;
  isLoadingNext?: boolean;
  alert?: FormHeaderAlert;
  onPrimaryDisabledClick?: () => void;
  showBottomActionBar?: boolean;
  childrenContainerSx?: SxProps<Theme>;
};

function PageLayout({
  header,
  subheader,
  children,
  onNext,
  onBack,
  nextDisabled,
  isNextSubmit,
  primaryText,
  isLoadingNext,
  alert,
  onPrimaryDisabledClick,
  showBottomActionBar = true,
  childrenContainerSx,
}: Props) {
  const theme = useTheme();
  return (
    <Box>
      {(!isNil(header) || !isNil(subheader) || !!alert) && (
        <FormHeader header={header || ''} subHeader={subheader} alert={alert} />
      )}
      <Box
        sx={{
          width: 'calc(100% - 32px)',
          maxWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '0px 16px',
          ...sxToObject(childrenContainerSx, theme),
        }}>
        {children}
      </Box>
      {showBottomActionBar && (
        <BottomActionBar
          isLoading={isLoadingNext}
          primaryText={primaryText ?? 'Next'}
          isSubmit={isNextSubmit}
          primaryAction={onNext}
          secondaryAction={onBack}
          secondaryText="Back"
          primaryDisabled={nextDisabled}
          onPrimaryDisabledClick={onPrimaryDisabledClick}
        />
      )}
      <PorterFooter />
    </Box>
  );
}

export default PageLayout;
