import React from 'react';
import {
  ScheduleAndConfirmAction,
  ScheduleAndConfirmState,
} from '../../types/appointments';
import { Appointment } from '../../interfaces';

const reducer = (
  state: ScheduleAndConfirmState,
  action: ScheduleAndConfirmAction,
) => {
  switch (action.type) {
    case 'step':
      return {
        ...state,
        step: action.payload,
      };
    case 'selectedDay':
      return {
        ...state,
        selectedDay: action.payload,
      };
    case 'selectedAppointmentId':
      return {
        ...state,
        selectedAppointmentId: action.payload,
      };
    case 'appointmentsToDates':
      return {
        ...state,
        appointmentsToDates: {
          ...state.appointmentsToDates,
          [action.payload.appointmentId]: action.payload.date,
        },
      };
    case 'setAppointmentsToDates':
      return {
        ...state,
        appointmentsToDates: action.payload,
      };
    default:
      return state;
  }
};

function useScheduleAndConfirm(appointment: Appointment) {
  const [state, dispatch] = React.useReducer(reducer, {
    step: 'details',
    selectedDay: new Date(),
    selectedAppointmentId: appointment.appointmentId!,
    appointmentsToDates: {
      [appointment.appointmentId!]: new Date(),
    },
  });
  const [dayIsSelected, setDayIsSelected] = React.useState(false);

  const onChangeStep = React.useCallback(
    (step: ScheduleAndConfirmState['step']) => {
      dispatch({ type: 'step', payload: step });
    },
    [dispatch],
  );

  const onChangeSelectedDay = React.useCallback(
    (day: ScheduleAndConfirmState['selectedDay']) => {
      dispatch({ type: 'selectedDay', payload: day });
    },
    [dispatch],
  );

  const onChangeSelectedAppointmentId = React.useCallback(
    (appointmentId: ScheduleAndConfirmState['selectedAppointmentId']) => {
      dispatch({ type: 'selectedAppointmentId', payload: appointmentId });
    },
    [dispatch],
  );

  const onChangeAppointmentsToDates = React.useCallback(
    ({ appointmentId, date }: { appointmentId: string; date: Date | null }) => {
      dispatch({
        type: 'appointmentsToDates',
        payload: { appointmentId, date },
      });
    },
    [dispatch],
  );

  const setAppointmentsToDates = React.useCallback(
    (appointmentsToDates: ScheduleAndConfirmState['appointmentsToDates']) => {
      dispatch({
        type: 'setAppointmentsToDates',
        payload: appointmentsToDates,
      });
    },
    [dispatch],
  );

  return {
    ...state,
    dayIsSelected,
    setDayIsSelected,
    onChangeStep,
    onChangeSelectedDay,
    onChangeSelectedAppointmentId,
    onChangeAppointmentsToDates,
    setAppointmentsToDates,
  };
}

export default useScheduleAndConfirm;
