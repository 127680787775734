import { useMutation } from '@tanstack/react-query';
import { pdf } from '@react-pdf/renderer';
import { Buffer } from 'buffer';
import { WaiverRequests } from '@prisma/client';
import useUser from '../global/useUser';
import { Appointment, Business } from '../../interfaces';
import { uploadFiles } from '../../services/global';
import { convertPdfToImages } from '../../utils/pdf';
import { WaiverForm, waiverFormSchema } from '../../types/waivers';
import useWaiver from './useWaiver';
import WaiverPDF from '../../components/Booking/Waiver/WaiverPDF';
import { submitWaiver } from '../../services/waivers';
import { isWaiverSessionExpired } from '../../utils/waivers';

interface SubmitWaiverOptions {
  appointment: Appointment | undefined;
  waiverRequest: WaiverRequests | undefined;
  business: Business | undefined;
  sessionId?: string;
  onSuccess?: () => void | Promise<void>;
  onError?: (error: Error, variables: WaiverForm) => void | Promise<void>;
  onSessionExpiredError?: () => void | Promise<void>;
}

function useSubmitWaiver({
  appointment: initialAppointment,
  waiverRequest,
  business,
  sessionId,
  onSuccess,
  onError,
  onSessionExpiredError,
}: SubmitWaiverOptions) {
  const { user } = useUser();
  const { waiver, isFetching, appointment, session } = useWaiver({
    appointment: initialAppointment,
    sessionId,
  });

  const submitWaiverMutation = useMutation({
    mutationFn: async (payload: WaiverForm) => {
      if (
        !appointment ||
        !waiverRequest?.id ||
        !waiver ||
        !business ||
        isFetching ||
        (!user && !sessionId)
      )
        throw new Error('Invalid waiver');

      if (session && isWaiverSessionExpired(session)) {
        onSessionExpiredError?.();
        throw new Error('Session expired');
      }

      const data = (await waiverFormSchema.validate(payload)) as WaiverForm;

      if (
        waiver.acknowledgmentSettings?.enabled &&
        !!waiver.acknowledgmentSettings.options?.length
      ) {
        const allChecked = Object.values(data.acknowledgments).every(Boolean);
        if (!allChecked) throw new Error('Invalid acknowledgments');
      }

      const imageResponse = data.identification
        ? await fetch(data.identification).catch(() => null)
        : null;

      const idArrayBuffer = await imageResponse?.arrayBuffer();
      const idBuffer = idArrayBuffer ? Buffer.from(idArrayBuffer) : undefined;
      if (waiver.identificationSettings?.enabled && !idBuffer)
        throw new Error('Invalid identification');

      let staticWaiverUrls: string[] = [];

      if (
        waiver.staticWaiverSettings?.enabled &&
        waiver.staticWaiverSettings?.documentLink
      ) {
        const staticWaiverResponse = await fetch(
          waiver.staticWaiverSettings?.documentLink,
        );
        if (!staticWaiverResponse.ok) {
          throw new Error('Invalid static waiver');
        }
        const blob = await staticWaiverResponse.blob();
        staticWaiverUrls = await convertPdfToImages(blob);
      }

      const instance = pdf(
        <WaiverPDF
          appointment={appointment}
          business={business}
          waiverForm={data}
          waiver={waiver}
          idBuffer={idBuffer}
          staticWaiverImages={staticWaiverUrls}
        />,
      );
      const pdfBlob = await instance.toBlob();
      const pdfLocalUrl = URL.createObjectURL(pdfBlob);

      const isPublicUpload = !user && !!sessionId;
      const tempFileName = `${business.businessId}-${appointment.appointmentId}`;
      const baseUrl = `tempImages/${tempFileName}`;

      const uploads = [
        {
          id: 'waiver',
          path: `${baseUrl}-signed-waiver`,
          url: pdfLocalUrl,
        },
      ];

      if (data.identification) {
        uploads.push({
          id: 'identification',
          path: `${baseUrl}-identification-upload`,
          url: data.identification,
        });
      }

      const [pdfUrl, idUrl] = await uploadFiles(uploads, {
        onSuccess: img => {
          const isObjectUrl = new URL(img.url).protocol === 'blob:';
          if (isObjectUrl) URL.revokeObjectURL(img.url);
        },
        isPublicUpload,
      });

      const request = await submitWaiver({
        data: {
          acknowledgments: data.acknowledgments,
          medicalHistory: data.medicalHistory,
          customerDetails: data.customerDetails,
        },
        signedFileLink: pdfUrl,
        identificationImageLink: idUrl,
        waiverRequestId: waiverRequest?.id,
        waiverRequestSessionId: sessionId,
      });

      return request;
    },
    onSuccess,
    onError,
  });

  return {
    submitWaiverMutation,
    variables: submitWaiverMutation.variables,
    isPending: submitWaiverMutation.isPending,
    isSuccess: submitWaiverMutation.isSuccess,
    isError: submitWaiverMutation.isError,
    error: submitWaiverMutation.error,
  };
}

export default useSubmitWaiver;
