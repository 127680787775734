import { WaiverOptions, WaiverRequestSessions } from '@prisma/client';
import { isBefore } from 'date-fns';
import { Business, User } from '../interfaces';
import { WaiverForm, FullWaiver } from '../types/waivers';

export const getWaiverSessionStorageKey = (
  userId: string,
  waiverId: string,
  storageIdentifier: string,
) => `waiver-${userId}-${waiverId}-${storageIdentifier}`;

const getPersistedOptionValue = (
  data: WaiverForm | null,
  option: WaiverOptions,
  type: 'acknowledgments' | 'medicalHistory',
): boolean | string => {
  const value = typeof data === 'object' ? data?.[type]?.[option.id] : null;
  // eslint-disable-next-line no-nested-ternary
  return option.type === 'CHECKBOX'
    ? typeof value === 'boolean' && value
    : typeof value === 'string'
    ? value
    : '';
};

export const storeWaiverForm = (
  passedUser: User | null,
  waiver: FullWaiver,
  storageIdentifier: string,
  waiverForm: WaiverForm | null,
  isSession = false,
) => {
  if (!waiverForm) return;
  const user = isSession ? null : passedUser;

  sessionStorage.setItem(
    getWaiverSessionStorageKey(
      user?.userId ?? 'unknown-user',
      waiver.id,
      storageIdentifier,
    ),
    JSON.stringify(waiverForm),
  );
};

export const getInitialWaiverForm = (
  passedUser: User | null,
  waiver: FullWaiver,
  storageIdentifier: string,
  isPreview = false,
  isSession = false,
): WaiverForm => {
  const user = isSession ? null : passedUser;

  const persistedData = sessionStorage.getItem(
    getWaiverSessionStorageKey(
      user?.userId ?? 'unknown-user',
      waiver.id,
      storageIdentifier,
    ),
  );

  const parsedPersistedData = persistedData
    ? (JSON.parse(persistedData) as WaiverForm)
    : null;
  const parsedCustomerDetails = parsedPersistedData?.customerDetails;

  return {
    // don't persist customer details
    customerDetails: {
      firstName: isSession
        ? parsedCustomerDetails?.firstName || ''
        : user?.firstName || (isPreview ? 'Waiver' : ''),
      lastName: isSession
        ? parsedCustomerDetails?.lastName || ''
        : user?.lastName || (isPreview ? 'Preview' : ''),
      phoneNumber: isSession
        ? parsedCustomerDetails?.phoneNumber || ''
        : user?.phoneNumber || (isPreview ? '+13104567890' : ''),
      email: isSession
        ? parsedCustomerDetails?.email || ''
        : user?.email || (isPreview ? 'johndoe@email.com' : ''),
      dob: isSession
        ? parsedCustomerDetails?.dob || ''
        : user?.customer?.dob || (isPreview ? '2000-01-01' : ''),
    },
    acknowledgments:
      waiver.acknowledgmentSettings?.options.reduce(
        (acc, option) => ({
          ...acc,
          [option.id]: getPersistedOptionValue(
            parsedPersistedData,
            option,
            'acknowledgments',
          ),
        }),
        {},
      ) || {},
    medicalHistory:
      waiver.medicalHistorySettings?.options.reduce(
        (acc, option) => ({
          ...acc,
          [option.id]: getPersistedOptionValue(
            parsedPersistedData,
            option,
            'medicalHistory',
          ),
        }),
        {},
      ) || {},
    identification: '',
    staticLiabilitySignature: '',
    signature: '',
  };
};

export const getDefaultStaticLiabilityText = ({
  employee,
  business,
}: {
  employee: {
    firstName: string;
    lastName: string;
  };
  business: Business;
}) =>
  `I (referred to as “I” or “me”) desire to receive a tattoo (the “Activity”) from ${employee.firstName} ${employee.lastName}, a Tattoo Artist based out of ${business.city}, ${business.state} (the “Company”). I agree, on behalf of myself, my heirs, and my personal representatives, to all the terms and conditions set forth in this Consent and Release by clicking “I accept” below (this “Release”).

I AM FULLY INFORMED, AWARE AND UNDERSTAND THAT GETTING A TATTOO IS A POTENTIALLY DANGEROUS ACTIVITY AND INVOLVES INHERENT RISKS. I ACKNOWLEDGE THAT ANY INJURIES THAT I SUSTAIN MAY RESULT FROM OR BE COMPOUNDED BY THE ACTIONS, OMISSIONS, OR NEGLIGENCE OF THE COMPANY. <b>NOTWITHSTANDING THE RISK, I ACKNOWLEDGE THAT I AM KNOWINGLY AND VOLUNTARILY PARTICIPATING IN THE ACTIVITY WITH AN EXPRESS UNDERSTANDING OF THE DANGER INVOLVED AND HEREBY AGREE TO ACCEPT AND ASSUME ANY AND ALL RISKS ARISING FROM GETTING A TATTOO FROM THE COMPANY, WHETHER CAUSED BY THE NEGLIGENCE OF THE COMPANY OR OTHERWISE. SPECIFICALLY, I REPRESENT, WARRANT AND AGREE (AS APPLICABLE) THAT:</b>

&emsp;&emsp;1. I am over eighteen (18) years of age.

&emsp;&emsp;2. Risks, known and unknown, of obtaining a tattoo can lead to injury including but not limited to infection, scarring, difficulties in the detection of skin cancers or conditions, and allergic or other adverse reactions to tattoo pigment, materials or products used in application, aftercare products and/or soap. Having been informed of the potential risks associated with getting a tattoo, I wish to proceed with the tattoo procedure and application and freely accept and expressly assume any and all risks that may arise from tattooing.

&emsp;&emsp;3. The Company has given me the full opportunity to ask any question about the procedure and application of my tattoo and all of my questions have been answered to my satisfaction and I have been given instructions on aftercare. The procedure and application may result in infection, particularly if I fail to follow the aftercare instructions given to me.

&emsp;&emsp;4. I am not under the influence of alcohol or drugs, and I am voluntarily submitting to be tattooed by the Company without duress or coercion. I do not have diabetes, epilepsy, hemophilia, heart condition(s), any communicable disease and do not take any medication that works as or has side effects of blood thinning. I do not have any other medical or skin condition that may interfere with the procedure, application or healing of the tattoo. I am not the recipient of an organ or bone marrow transplant or, if I am, I have taken the prescribed preventative regimen of antibiotics that is required by my doctor in advance of any invasive procedure such as tattooing or piercing. I am not pregnant or nursing. I do not have a mental impairment that may affect my judgement in getting the tattoo.

&emsp;&emsp;5. I have provided or selected my tattoo design myself. Accordingly, the Company is not responsible for the meaning or spelling of the symbol or text that I have provide to them or chosen from design examples. I acknowledge that variations in color and design may exist between the tattoo art I have selected and the actual tattoo when it is applied. I also understand that over time, the colors and the clarity of my tattoo will fade due to unprotected exposure to the sun and the naturally occurring dispersion of pigment under the skin.

&emsp;&emsp;6. A tattoo is a permanent change to my appearance and can only be removed by laser or surgical means, which can be disfiguring and/or costly and which in all likelihood will not result in the restoration of my skin to its exact appearance before being tattooed.

&emsp;&emsp;7. I acknowledge that the Company has a NO REFUND policy on tattoos, piercing and/or retail sales, and I will not ask for a refund for any reason whatsoever.

I hereby expressly waive and release any and all claims which I may have, or which I may hereafter have, whether known or unknown, against the Company, and its officers, directors, managers, employees, agents, contractors, affiliates, successors, and assigns (collectively, “Releasees”), arising out of or attributable to the Activity, whether arising out of the ordinary negligence of the Company or any Releasees or otherwise, including those claims that may be unknown to me or which I do not suspect at this time. I covenant not to make or bring any such claim against the Company or any other Releasee, and forever release and discharge the Company and all other Releasees from liability under such claims. This waiver and release does not extend to claims for gross negligence, intentional or reckless misconduct, or any other liabilities that applicable law does not permit to be released by agreement.

I hereby consent to receive from any licensed hospital, physician, or medical personnel any medical treatment deemed necessary if I am injured or require medical attention the procedure or application of the tattoo. I understand and agree that I am solely responsible for all costs related to such medical treatment and any related medical transportation and/or evacuation and I release all parties from any type of liability for anything that may happen during my treatment.

This Release constitutes the sole and entire agreement of the Company and me with respect to the subject matter contained herein and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter. If any term or provision of this Release is held invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Release or invalidate or render unenforceable such term or provision in any other jurisdiction. This Release is binding on and shall inure to the benefit of the Company and me and their respective heirs, successors, and assigns. All matters arising out of or relating to this Release shall be governed by and construed in accordance with the internal laws of the state in which the Company is located (as set forth above) without giving effect to any choice or conflict of law provision or rule. Any claim or cause of action arising under this Release may be brought only in the federal and state courts located in or having jurisdiction over such location and I hereby consent to the exclusive jurisdiction of such courts.

`;

export const isWaiverSessionExpired = (session: WaiverRequestSessions) => {
  if (!session?.expiresAt) return false;

  const expiresAt = new Date(session.expiresAt);
  const now = new Date();

  return isBefore(expiresAt, now);
};
