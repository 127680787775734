import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { getAptStartEnd } from '../../../utils/appointment';

interface Props {
  date: Date | null;
  duration: number;
  timezone?: string;
  changeDate?: {
    onClick: () => void;
    text: React.ReactNode;
  };
  removeDate?: {
    onClick: () => void;
    text: React.ReactNode;
  };
  totalCount?: number;
  index?: number;
  isConfirmed?: boolean;
  isCurrent?: boolean;
  isComplete?: boolean;
}

function AppointmentDateLineItem({
  date,
  duration,
  timezone = 'US/Pacific',
  changeDate,
  removeDate,
  totalCount,
  index,
  isConfirmed,
  isComplete,
  isCurrent,
}: Props) {
  const theme = useTheme();

  const displayCount = (totalCount || 0) > 1;

  const showBadge = isConfirmed || isComplete;
  const Icon = date ? WatchLaterIcon : InsertInvitationIcon;

  return (
    <Box className="schedule-detail-item">
      <Box position="relative" width="fit-content" height="fit-content">
        <Icon fontSize="medium" htmlColor={theme.palette.outlineGrey.main} />

        {isCurrent && !displayCount && (
          <Box
            position="absolute"
            top={-1.5}
            right={-1.5}
            borderRadius="50%"
            width={10}
            height={10}
            bgcolor={theme.palette.primary.main}
            color="white"
            display="flex"
          />
        )}

        {displayCount && typeof index === 'number' && (
          <Box
            position="absolute"
            top={-3}
            right={-3}
            borderRadius="50%"
            bgcolor={theme.palette.primary.main}
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={13}
            height={13}>
            <Typography
              variant="body2"
              color="inherit"
              fontWeight={600}
              fontSize="9px"
              textAlign="center"
              width="100%">
              {index + 1}
            </Typography>
          </Box>
        )}
      </Box>
      <Box gap={1}>
        {duration && date && (
          <Typography variant="body2">
            {showBadge && (
              <Typography
                fontSize="10px"
                px={0.5}
                py={0.2}
                variant="h3"
                component="span"
                sx={{
                  verticalAlign: 'middle',
                  borderRadius: '2px',
                  bgcolor: theme.palette.accent.main,
                  color: theme.palette.secondary.main,
                }}>
                {isComplete ? 'Completed' : 'Confirmed'}
              </Typography>
            )}
            {showBadge && ' '}
            {getAptStartEnd(date, duration, timezone)}
          </Typography>
        )}
        {(changeDate || removeDate) && (
          <Typography variant="body2">
            {changeDate && (
              <Button
                sx={{ minWidth: 0 }}
                variant="link"
                onClick={changeDate.onClick}
                component="span">
                <Typography
                  component="span"
                  variant="body2"
                  fontFamily={theme.typography.fontFamily}>
                  {changeDate.text}
                </Typography>
              </Button>
            )}
            {changeDate && removeDate && (
              <Typography
                variant="body2"
                component="span"
                sx={{ verticalAlign: 'bottom' }}
                fontFamily={theme.typography.fontFamily}>
                {' '}
                or{' '}
              </Typography>
            )}
            {removeDate && (
              <Button
                sx={{ minWidth: 0 }}
                variant="link"
                onClick={removeDate.onClick}
                component="span">
                <Typography
                  component="span"
                  variant="body2"
                  fontFamily={theme.typography.fontFamily}>
                  {removeDate.text}
                </Typography>
              </Button>
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default AppointmentDateLineItem;
