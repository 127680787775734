import PaymentSection from './PaymentSection';
import { Appointment, Employee } from '../../../interfaces';
import {
  AppointmentGroupWithAppointments,
  DateAction,
  getScheduleAcceptedAppointmentsSchema,
} from '../../../types/appointments';
import BottomActionBar from '../../Global/BottomActionBar';
import { getRequiredAppointmentsForConfirm } from '../../../utils/appointment';

interface Props {
  appointment: Appointment;
  appointmentGroup?: AppointmentGroupWithAppointments;
  appointmentsToDates: Record<string, Date | null>;
  status: 'schedule' | 'reschedule';
  employee: Employee;
  isMarketingOptIn?: boolean;
  isBack?: boolean;
  changeDateAction?: DateAction;
  backAction?: () => void;
}

function ConfirmActionSection({
  appointment,
  appointmentGroup,
  appointmentsToDates,
  status,
  employee,
  isBack,
  isMarketingOptIn,
  changeDateAction,
  backAction,
}: Props) {
  const schema = getScheduleAcceptedAppointmentsSchema(
    appointment,
    appointmentGroup,
  );
  const isValid = schema.isValidSync({
    appointmentsToDates,
  });

  if (!isValid) {
    const dateBackAction = () => {
      if (!changeDateAction) return;
      const reqiuredAppointments = getRequiredAppointmentsForConfirm(
        appointment,
        appointmentGroup,
      );
      const missingRequiredAppointments = reqiuredAppointments.filter(
        sppt => !appointmentsToDates[sppt.appointmentId!],
      );
      if (!missingRequiredAppointments.length) return;

      changeDateAction({
        appointmentId: missingRequiredAppointments[0].appointmentId!,
      });
    };

    return (
      <BottomActionBar
        primaryAction={dateBackAction}
        primaryText="Choose Next Date"
        secondaryAction={isBack ? backAction : undefined}
        secondaryText={isBack ? 'Back' : undefined}
      />
    );
  }

  return (
    <PaymentSection
      appointment={appointment}
      appointmentGroup={appointmentGroup}
      appointmentsToDates={appointmentsToDates}
      status={status}
      employee={employee}
      isBack={isBack}
      backAction={backAction}
      isMarketingOptIn={isMarketingOptIn}
    />
  );
}

export default ConfirmActionSection;
