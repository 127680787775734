import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Employee, Appointment, Business } from '../../../interfaces';
import '../Booking.css';
import AppointmentDetailsList from '../General/AppointmentDetailsList';
import BottomActionBar from '../../Global/BottomActionBar';
import Calendar from '../Scheduler/Calendar';
import Confirm from '../Confirm/Confirm';
import {
  AppointmentGroupWithAppointments,
  DateAction,
} from '../../../types/appointments';
import BookingContainer from '../General/BookingContainer';
import useScheduleAndConfirm from '../../../hooks/appointments/useScheduleAndConfirm';
import {
  getConfirmedAppointmentsInGroup,
  getRequiredAppointmentsForConfirm,
} from '../../../utils/appointment';

type Props = {
  appointment: Appointment;
  appointmentGroup?: AppointmentGroupWithAppointments;
  employee: Employee;
  business: Business | undefined;
};

function ScheduleAndConfirm({
  appointment,
  appointmentGroup,
  employee,
  business,
}: Props) {
  const {
    step,
    selectedDay,
    appointmentsToDates,
    dayIsSelected,
    selectedAppointmentId,
    setDayIsSelected,
    onChangeStep,
    onChangeAppointmentsToDates,
    setAppointmentsToDates,
    onChangeSelectedAppointmentId,
    onChangeSelectedDay,
  } = useScheduleAndConfirm(appointment);

  const calendarAppointment = React.useMemo(
    () =>
      appointmentGroup
        ? appointmentGroup.appointments.find(
            apt => apt.appointmentId === selectedAppointmentId,
          )
        : appointment,
    [appointment, appointmentGroup, selectedAppointmentId],
  );

  const onChangeDate: DateAction = React.useCallback(
    ({ appointmentId }) => {
      const currentDateTime = appointmentsToDates[appointmentId];
      onChangeSelectedAppointmentId(appointmentId);
      onChangeSelectedDay(
        currentDateTime ? new Date(currentDateTime) : new Date(),
      );
      setDayIsSelected(!!currentDateTime);
      setTimeout(() => onChangeStep('dateTime'), 0);
    },
    [
      appointmentsToDates,
      onChangeSelectedAppointmentId,
      onChangeSelectedDay,
      onChangeStep,
      setDayIsSelected,
    ],
  );

  const onRemoveDate: DateAction = React.useCallback(
    ({ appointmentId }) => {
      // remove button is only shown for optional appointments, but this safely handles the case where a required appointment is removed
      const requiredAppointments = getRequiredAppointmentsForConfirm(
        appointment,
        appointmentGroup,
      );
      const optionalAppointments = (
        appointmentGroup?.appointments ?? []
      ).filter(
        apt =>
          !requiredAppointments.some(
            req => req.appointmentId === apt.appointmentId,
          ),
      );
      const tempNewAppointmentsToDates = {
        ...appointmentsToDates,
      };
      delete tempNewAppointmentsToDates[appointmentId];

      const dates = [...Object.values(tempNewAppointmentsToDates)].filter(
        Boolean,
      ) as Date[];
      const appointments = [...requiredAppointments, ...optionalAppointments];

      const finalAppointmentsToDates: Record<string, Date> = {};
      while (dates.length > 0 && appointments.length > 0) {
        const date = dates.shift();
        const appt = appointments.shift();
        if (date && appt) {
          finalAppointmentsToDates[appt.appointmentId!] = date;
        }
      }

      setAppointmentsToDates(finalAppointmentsToDates);
    },
    [
      appointment,
      appointmentGroup,
      appointmentsToDates,
      setAppointmentsToDates,
    ],
  );

  const getOtherSelectedDates = React.useCallback(() => {
    if (!appointmentGroup) return [];
    const datesFromConfirmedAppointments = getConfirmedAppointmentsInGroup(
      appointmentGroup,
    ).map(apt => (apt.startDateTime ? new Date(apt.startDateTime) : null));
    const otherAppointmentsDates = Object.entries(appointmentsToDates)
      .filter(([appointmentId]) => appointmentId !== selectedAppointmentId)
      .map(([, date]) => (date ? new Date(date) : null));

    return datesFromConfirmedAppointments
      .concat(otherAppointmentsDates)
      .filter(Boolean) as Date[];
  }, [appointmentGroup, appointmentsToDates, selectedAppointmentId]);

  switch (step) {
    case 'details':
      return (
        <BookingContainer
          sx={{
            paddingBottom: '165px',
          }}>
          <Box className="form-header-title">
            <Typography variant="h2" className="form-header">
              {employee.displayName || employee.user.firstName || 'Your artist'}{' '}
              accepted your booking request!
            </Typography>
            <Typography variant="body2" className="form-sub-header">
              Review details and schedule your appointment
            </Typography>
          </Box>
          <AppointmentDetailsList
            appointment={appointment}
            appointmentGroup={appointmentGroup}
            employee={employee}
            business={business}
            location
            length
            price
            designMessage
            additionalAppointmentsMessage
            showConfirmedAppointments
          />
          <BottomActionBar
            primaryText="Choose Date"
            primaryAction={() => {
              onChangeStep('dateTime');
              window.scrollTo(0, 0);
            }}
          />
        </BookingContainer>
      );

    case 'dateTime':
      return (
        <BookingContainer
          sx={{
            paddingBottom: dayIsSelected ? '125px' : '38px',
          }}>
          {calendarAppointment && employee && (
            <Calendar
              duration={calendarAppointment.appointmentLengthInMinutes!}
              employee={employee}
              appointment={calendarAppointment}
              selectedDay={selectedDay}
              dayIsSelected={dayIsSelected}
              setSelectedDay={onChangeSelectedDay}
              setSelectedDateTime={date => {
                onChangeAppointmentsToDates({
                  appointmentId: selectedAppointmentId,
                  date,
                });
              }}
              setDayIsSelected={setDayIsSelected}
              setStep={onChangeStep}
              bufferDays={appointmentGroup?.bufferDays}
              otherSelectedDates={getOtherSelectedDates()}
            />
          )}
        </BookingContainer>
      );

    case 'confirm':
      return (
        <BookingContainer
          sx={{
            paddingBottom: '125px',
          }}>
          <Confirm
            appointment={appointment}
            appointmentGroup={appointmentGroup}
            employee={employee}
            business={business}
            appointmentsToDates={appointmentsToDates}
            status="schedule"
            isBack
            changeDateAction={onChangeDate}
            removeDateAction={onRemoveDate}
            backAction={() => onChangeStep('dateTime')}
            isScheduleAdditional
          />
        </BookingContainer>
      );

    default:
      return (
        <BookingContainer
          sx={{
            paddingBottom: '125px',
          }}>
          <Typography variant="h1">
            There was an error loading your booking. Please reach out to our
            support team using the help icon above.
          </Typography>
        </BookingContainer>
      );
  }
}

export default ScheduleAndConfirm;
