import { useQuery } from '@tanstack/react-query';
import { getService } from '../../services/services';

function useGetService(serviceId: number) {
  return useQuery({
    queryKey: ['service', serviceId],
    queryFn: () => getService(serviceId),
  });
}

export default useGetService;
