/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Business } from '../interfaces';
import Form from '../components/Form/Form';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import ProgressBar from '../components/EmployeeHeader/ProgressBar';
import { getBusinessByUrlPath } from '../services/business';
import { ArtistSelectionScreen } from '../components/Form/ArtistSelection';
import { FIRST_AVAILABLE_ARTIST_ID } from '../constants/form';
import { DEFAULT_TATTOO_SERVICE_ID } from '../constants/global';

export default function GeneralRequest() {
  const { shopUrl } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);

  const [business, setBusiness] = React.useState<Business>();

  const [progress, setProgress] = React.useState('tattoo details');
  const [step, setStep] = React.useState(1);

  const [businessError, setBusinessError] = React.useState(false);
  const [selectedArtists, setSelectedArtists] = React.useState<string[]>([]);
  const [hasSelectedArtists, setHasSelectedArtists] = React.useState(false);

  React.useEffect(() => {
    if (sessionStorage.getItem('progress') !== null) {
      setProgress(sessionStorage.getItem('progress')!);
    }
  }, []);

  React.useEffect(() => {
    if (sessionStorage.getItem('step') !== null) {
      setStep(JSON.parse(sessionStorage.getItem('step')!));
    }
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      const tempBusiness = await getBusinessByUrlPath(shopUrl).catch(() => {
        setBusinessError(true);
      });

      if (tempBusiness) {
        window._cio.page('General Request', {
          shopName: tempBusiness.name,
        });

        setBusiness({
          ...tempBusiness,
        });
      }

      setLoading(false);
    }

    if (loading) {
      fetchData();
    }
  }, [loading, shopUrl]);

  // Add effect to load selected artists from session storage on mount
  React.useEffect(() => {
    const savedArtists = sessionStorage.getItem('selectedArtists');
    if (savedArtists) {
      const parsedArtists = JSON.parse(savedArtists);

      const currentArtists = parsedArtists.filter(
        (artistId: string) =>
          artistId === FIRST_AVAILABLE_ARTIST_ID ||
          business?.employees?.some(
            employee => employee.employeeId === artistId,
          ),
      );

      setSelectedArtists(currentArtists);
      setHasSelectedArtists(true);
    }
  }, [business]);

  const handleArtistSelection = (artistId: string) => {
    setSelectedArtists(prev => {
      const newSelection = prev.includes(artistId)
        ? prev.filter(id => id !== artistId) // Remove if already selected
        : [...prev, artistId]; // Add if not selected

      // Save to session storage
      sessionStorage.setItem('selectedArtists', JSON.stringify(newSelection));
      return newSelection;
    });
  };

  const handleContinue = () => {
    if (selectedArtists.length > 0) {
      const singleSelectedArtist =
        selectedArtists.length === 1
          ? business?.employees?.find(e => e.employeeId === selectedArtists[0])
          : null;

      if (singleSelectedArtist) {
        const searchParams = new URLSearchParams();
        searchParams.set('service', DEFAULT_TATTOO_SERVICE_ID);

        navigate(
          `/artist/${singleSelectedArtist.urlPath}?${searchParams.toString()}`,
        );
      } else {
        setHasSelectedArtists(true);
      }
    }
  };

  switch (true) {
    // Everything is loading
    case loading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Loaded, no open request, proceed with form
    case !loading && !businessError:
      return !hasSelectedArtists ? (
        <ArtistSelectionScreen
          business={business}
          selectedArtists={selectedArtists}
          handleArtistSelection={handleArtistSelection}
          handleContinue={handleContinue}
        />
      ) : (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={business?.name}
            subtitle="Booking Request"
            step={step}
          />
          <ProgressBar
            progress={progress}
            step={step}
            intakeQuestions={business?.intakeQuestions}
          />
          <Form
            progress={progress}
            setProgress={setProgress}
            step={step}
            setStep={setStep}
            business={business}
            intakeQuestions={business?.intakeQuestions}
            selectedArtists={selectedArtists}
          />
          <PorterFooter />
        </Box>
      );

    case businessError:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this shop&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this shop&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );
  }
}
