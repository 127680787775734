import * as yup from 'yup';
import { Business, Service, ServiceFormValues } from '../interfaces';
import {
  DEFAULT_SERVICE_DURATION,
  DEFAULT_SERVICE_PRICE,
  DEFAULT_STEPS,
  SERVICE_BOOKING_STEPS,
  BookingSteps,
} from '../constants/services';
import { FullService } from '../types/services';

export const transformAndFilterServices = (
  services: Service[],
  bookableBusinesses: Pick<Business, 'businessId' | 'urlPath'>[],
) => {
  const businessMap = new Map(
    bookableBusinesses.map(business => [business.businessId, business.urlPath]),
  );

  return services.reduce((acc, service) => {
    if (service.enableDirectBookings && businessMap.has(service.businessId)) {
      const urlPath = businessMap.get(service.businessId);
      acc.push({
        ...service,
        bookingUrl: `/shop/${urlPath}/service/${service.serviceId}`,
      });
    }
    return acc;
  }, [] as Service[]);
};

export const getSteps = (service?: Pick<Service, 'serviceCategory'> | null) => {
  if (!service) return DEFAULT_STEPS;
  return SERVICE_BOOKING_STEPS[service.serviceCategory] ?? DEFAULT_STEPS;
};

export const getBaseStorageIdentifier = (
  service?: Pick<Service, 'serviceId'> | null,
) => `service-${service?.serviceId ?? ''}`;

export const getStepStorageIdentifier = (identifier: string) =>
  `${identifier}-step`;

export const getFormValuesStorageIdentifier = (identifier: string) =>
  `${identifier}-formValues`;

export const serviceFormSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().optional(),
  email: yup.string().required('Email is required'),
  dob: yup.date().optional(),
  numberOfGuests: yup.number().optional(),
  piercingLocationCategory: yup.string().optional(),
  piercingLocationId: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional(),
  employeeId: yup.string().required(),
  startDateTime: yup.date().required(),
  isMarketingOptIn: yup.boolean().nullable().optional(),
});

export const isServiceLocationLocked = (
  service?: Pick<FullService, 'serviceCategory' | 'piercingLocations'> | null,
) =>
  service?.serviceCategory === 'PIERCING' &&
  service?.piercingLocations.length === 1;

export const getInitialServiceForm = ({
  storageIdentifier,
  employeeId,
  service,
}: {
  storageIdentifier: string;
  employeeId?: string | null;
  service: FullService | null | undefined;
}): ServiceFormValues => {
  const valuesIdentifier = getFormValuesStorageIdentifier(storageIdentifier);
  const persistedData = sessionStorage.getItem(valuesIdentifier);

  const parsedPersistedData = persistedData
    ? (JSON.parse(persistedData) as ServiceFormValues)
    : null;

  const isLockedLocation = isServiceLocationLocked(service);
  const lockedLocation = isLockedLocation
    ? service!.piercingLocations[0]
    : null;
  const piercingLocationId = lockedLocation
    ? lockedLocation.locationId
    : parsedPersistedData?.piercingLocationId;
  const piercingLocationCategory = isLockedLocation
    ? lockedLocation?.locationCategory
    : parsedPersistedData?.piercingLocationCategory;

  return {
    firstName: parsedPersistedData?.firstName || '',
    lastName: parsedPersistedData?.lastName || '',
    email: parsedPersistedData?.email || '',
    dob: parsedPersistedData?.dob,
    numberOfGuests: parsedPersistedData?.numberOfGuests,
    piercingLocationCategory: piercingLocationCategory || '',
    piercingLocationId: piercingLocationId || '',
    employeeId: parsedPersistedData?.employeeId || employeeId || '',
    startDateTime: parsedPersistedData?.startDateTime
      ? new Date(parsedPersistedData?.startDateTime)
      : undefined,
    isMarketingOptIn: parsedPersistedData?.isMarketingOptIn ?? true,
  };
};

export const getServiceDuration = (
  service?: Pick<Service, 'defaultDurationInMinutes'>,
  numberOfGuests?: number,
) =>
  (numberOfGuests ?? 1) *
  (service?.defaultDurationInMinutes ?? DEFAULT_SERVICE_DURATION);

export const getServicePrice = (
  service?: Pick<Service, 'defaultPrice'>,
  numberOfGuests?: number,
) => (numberOfGuests ?? 1) * (service?.defaultPrice ?? DEFAULT_SERVICE_PRICE);

export const getStep = (
  service: FullService | null | undefined,
  currentStep: BookingSteps,
  move: number,
) => {
  const steps = getSteps(service);
  const currentStepIndex = steps.indexOf(currentStep);
  const newStepIndex = currentStepIndex + move;
  const newStep = steps[newStepIndex];
  return newStep ? newStepIndex : currentStepIndex;
};
